let configurePlotOptions = (chartData) => {
	let {
		chartFormat: {
			chart: { type },
			plotOptions,
		},
	} = chartData;

	let incomingPlotOptions = plotOptions || {};
	let incomingSeriesPlotOptions = plotOptions?.series || {};

	if (type === "pie") {
		plotOptions = {
			...incomingPlotOptions,
			pie: plotOptions?.pie || {},
			series: {
				...incomingSeriesPlotOptions,
				animation: false,
			},
		};
	} else if (type === "gauge" || type === "solidgauge") {
		plotOptions = {
			...incomingPlotOptions,
			gauge: plotOptions?.gauge || {},
			series: { ...incomingSeriesPlotOptions, animation: false },
		};
	} else if (type === "polar") {
		plotOptions = {
			...incomingPlotOptions,
			series: { ...incomingSeriesPlotOptions, animation: false },
		};
	}

	return plotOptions || {};
};

export default configurePlotOptions;
