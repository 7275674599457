/* eslint-disable no-lonely-if */
/* eslint-disable unicorn/prefer-ternary */

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import { compareTab, isManageElementsPage, isManageScorecardsPage } from "@clearpoint/utils";

import { isManagePeriodsPage, isManageUsersPage } from "./isAdminPage";
import { isFrameworkAIAssistantPage, isGoalsAIAssistantPage, isScorecardAIAssistantPage } from "./isAIPage";
import { isAiPage } from "./isAiPage";
import { isIntegrationsPage, isSchedulePage, isScriptingPage } from "./isAutomationPage";
import { isDocumentPage, isFilePage, isTemplatePage } from "./isDocumentPage";
import { isGoalsTreePage, isManageGoalsPage, isMyGoalsPage } from "./isGoalsPage";
import isManageLayoutsPage from "./isManageLayoutsPage";
import isTreePage from "./isTreePage";
import removeSSOParameters from "./removeSSOParameters";

let updateTabbedNavigationData = ({ historyStack, profile, session }) => {
	if (!profile) return;
	let tabbedNavigationData = session.tabbedNavigationData || profile.preferences?.tabList || [];
	let filteredHistoryStack = [...historyStack].filter((x) => x.pathname !== "/index/home");

	let cleanTabData = (tab) => {
		delete tab.activeFlag;
		delete tab.pinnedFlag;
		delete tab.commentId;
		delete tab.sessionId;
		delete tab.token;
		return tab;
	};

	let tabbedNavigationDataWithoutActiveOrPinnedFlags = cloneDeep(tabbedNavigationData).map((x) => cleanTabData(x));

	let isManagePage = (x) => isManageElementsPage(x) || isManageLayoutsPage(x);
	let isGoalsPage = (x) => isManageGoalsPage(x) || isGoalsTreePage(x) || isMyGoalsPage(x);
	let updateScorecardIdOnManagePages = (tabbedNavigationData) => {
		return tabbedNavigationData.map((x) => (isManagePage(x) ? { ...x, scorecardId: session.scorecardId } : x));
	};

	if (
		!isEqual(tabbedNavigationDataWithoutActiveOrPinnedFlags, filteredHistoryStack) &&
		filteredHistoryStack.length > 0
	) {
		// get the last element of historyStack array
		let newTab = filteredHistoryStack.at(-1);
		let newTabWithoutActiveOrPinnedFlag = cleanTabData(cloneDeep(newTab));

		// check if tabbedNavigationData contains that element and find its index
		let index = tabbedNavigationDataWithoutActiveOrPinnedFlags.findIndex((x) => {
			// goals page tabs
			if (isGoalsTreePage(newTab)) return isGoalsTreePage(x);
			if (isManageGoalsPage(newTab)) return isManageGoalsPage(x);
			if (isMyGoalsPage(newTab)) return isMyGoalsPage(x);

			if (isFrameworkAIAssistantPage(newTab)) return isFrameworkAIAssistantPage(x);
			if (isGoalsAIAssistantPage(newTab)) return isGoalsAIAssistantPage(x);
			if (isScorecardAIAssistantPage(newTab)) return isScorecardAIAssistantPage(x);

			// automation
			if (isSchedulePage(newTab)) return isSchedulePage(x);
			if (isIntegrationsPage(newTab)) return isIntegrationsPage(x);
			if (isScriptingPage(newTab)) return isScriptingPage(x);
			if (isAiPage(newTab)) return isAiPage(x);

			// admin
			if (isManagePeriodsPage(newTab)) return isManagePeriodsPage(x);
			if (isManageUsersPage(newTab)) return isManageUsersPage(x);

			// documents
			if (isDocumentPage(newTab)) return isDocumentPage(x);
			if (isTemplatePage(newTab)) return isTemplatePage(x);
			if (isFilePage(newTab)) return isFilePage(x);

			// scorecard tree tabs
			if (isTreePage(newTab)) return isTreePage(x);

			// manage pages tabs
			if (isManageScorecardsPage(newTab)) return isManageScorecardsPage(x);
			if (isManageElementsPage(newTab)) return isManageElementsPage(x);
			if (isManageLayoutsPage(newTab)) return isManageLayoutsPage(x);

			return compareTab(x, newTabWithoutActiveOrPinnedFlag);
		});

		let setActiveFlagFalseOnAll = (array) => array.map((x) => ({ ...x, activeFlag: false }));

		let openTabToRightOfCurrent = (tabbedNavigationDataArgument, tab) => {
			tab = removeSSOParameters(tab);
			let tabbedNavigationDataArray = cloneDeep(tabbedNavigationDataArgument);
			let activeTabIndex = tabbedNavigationDataArray.findIndex((x) => x.activeFlag);
			let fallbackIndex = tabbedNavigationDataArray.length - 1;
			let insertionIndex = activeTabIndex === -1 ? fallbackIndex : activeTabIndex;
			tabbedNavigationDataArray = setActiveFlagFalseOnAll(tabbedNavigationDataArray);
			return [
				...tabbedNavigationDataArray.slice(0, insertionIndex + 1),
				{ ...tab, activeFlag: true },
				...tabbedNavigationDataArray.slice(insertionIndex + 1),
			];
		};

		let replaceExistingTabWithNewTab = (tabbedNavigationDataArgument, newTab, existingTabIndex) => {
			newTab = removeSSOParameters(newTab);
			let tabbedNavigationDataArray = cloneDeep(tabbedNavigationDataArgument);
			tabbedNavigationDataArray = setActiveFlagFalseOnAll(tabbedNavigationDataArray);
			return [
				...tabbedNavigationDataArray.slice(0, existingTabIndex),
				{ ...newTab, activeFlag: true, pinnedFlag: tabbedNavigationDataArray[existingTabIndex]?.pinnedFlag },
				...tabbedNavigationDataArray.slice(existingTabIndex + 1),
			];
		};

		// if tabbedNavigationData doesn't contain the object, add it to the right of the current active tab
		if (index === -1) {
			tabbedNavigationData = openTabToRightOfCurrent(tabbedNavigationData, newTab);
		} else {
			let tab = tabbedNavigationData[index];
			if (
				isManagePage(tab) ||
				isTreePage(tab) ||
				isGoalsPage(tab) ||
				isSchedulePage(tab) ||
				isIntegrationsPage(tab) ||
				isScriptingPage(tab) ||
				isDocumentPage(tab) ||
				isTemplatePage(tab) ||
				isFilePage(tab) ||
				isAiPage(tab) ||
				isManagePeriodsPage(tab) ||
				isManageUsersPage(tab)
			) {
				tabbedNavigationData = replaceExistingTabWithNewTab(tabbedNavigationData, newTab, index);
			} else {
				tabbedNavigationData = setActiveFlagFalseOnAll(tabbedNavigationData);
				tabbedNavigationData[index].activeFlag = true;
			}
			//}
		}
	}

	tabbedNavigationData = updateScorecardIdOnManagePages(tabbedNavigationData);
	return tabbedNavigationData;
};

export default updateTabbedNavigationData;
