/* eslint-disable complexity */
import { useCallback } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { useHomepage, useScorecardId } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { objectIdListPropTypeArray, objectIdPropTypeNumberOrString, objectPropTypeString } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import Button from "../Button/Button";
import { useFormContext } from "../Form/Form";
import HTML from "../HTML";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import useToastApproval from "@clearpoint/hooks-dir/useToastApproval";
import { useOldSession } from "@clearpoint/old-session/index";
import { useChecklist } from "@clearpoint/providers/ChecklistProvider";

let propTypes = {
	body: PropTypes.string,
	close: PropTypes.func.isRequired,
	confirmButtonText: PropTypes.string,
	elementFlag: PropTypes.bool,
	listKey: PropTypes.string,
	method: PropTypes.oneOf(["post", "delete"]),
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
	object: (x, y, z) => objectPropTypeString(x, y, z),
	objectId: (x, y, z) => objectIdPropTypeNumberOrString(x, y, z),
	objectIdList: (x, y, z) => objectIdListPropTypeArray(x, y, z),
	onTrash: PropTypes.func,
	queryString: PropTypes.string,
	title: PropTypes.string,
	toastMessage: PropTypes.string,
};

let TrashModal = ({
	body,
	confirmButtonText,
	close,
	elementFlag,
	listKey,
	method,
	modalVisible,
	name,
	object,
	objectId,
	objectIdList,
	onTrash,
	queryString,
	title,
	toastMessage,
}) => {
	let translate = useTranslate();
	let formContext = useFormContext();
	let { clear, clearAssociated, trash } = useOldQueryStore();
	let checklist = useChecklist();
	let { session, setSession } = useOldSession();
	let { historyStack } = session;
	let reportFlag = object?.endsWith("Layout");
	let scorecardId = useScorecardId({ object, objectId });
	let toastApproval = useToastApproval();
	let { homepageFlag, toggleHomepage } = useHomepage({
		object: session.object,
		objectId: reportFlag ? undefined : objectId,
		layoutId: reportFlag ? objectId : undefined,
		scorecardId,
	});
	let trashObject = useCallback(
		async (e) => {
			if (e) {
				e.stopPropagation();
			}
			let approvalNeeded = false;
			if (
				object === "scorecard" &&
				(objectId === session.scorecardId || (objectIdList && objectIdList.includes(session.scorecardId)))
			) {
				toast.error(translate("toaster.messages.scorecards.cannotDelete"));
				close();
				return;
			}

			if (object === "period") {
				clearAssociated(object);
			}
			if (object === "defaultSeries") {
				clear({ object: "calculatedField" });
			}

			if (checklist && objectIdList) {
				checklist.setChecklist((checklist) =>
					checklist.map((x) => (objectIdList.includes(x.id) ? { ...x, checked: false } : x))
				);
			}

			if (checklist && objectId) {
				checklist.setChecklist((checklist) =>
					checklist.map((x) => (x.id === objectId ? { ...x, checked: false } : x))
				);
			}

			if (object && (objectId || objectIdList)) {
				if (object === "user" && objectIdList) {
					Promise.all(
						objectIdList.map((objectId) => {
							return trash({ object, objectId, method, listKey, queryString, elementFlag });
						})
					);
				} else {
					let response = await trash({
						object,
						objectId,
						objectIdList,
						method,
						listKey,
						queryString,
						elementFlag,
					});
					let { changeQueued } = toastApproval({ response });
					approvalNeeded = changeQueued;
				}
				clear({ object });
				clear({ object: "menu" });
			} else if (name) {
				let { setFormValue, setFormValidation } = formContext;
				if (name.endsWith("]")) {
					setFormValue(name);
					setFormValidation(name);
				} else {
					setFormValue(name, "");
				}
			}
			clear({ object: "menu", parent: "scorecard", parentId: scorecardId });
			if (onTrash) await onTrash(e);
			if (toastMessage) toast.success(toastMessage);

			if (!toastMessage && !approvalNeeded && object && objectId) {
				toast.success(
					translate(
						reportFlag ? "toaster.messages.reports.reportDeleted" : "toaster.messages.objects.objectDeleted",
						{ object: `global.${object}` }
					)
				);
			}
			let filteredHistoryStack;
			if (object === "scorecard" && objectId) {
				filteredHistoryStack = historyStack.filter((x) => x.scorecardId !== objectId);
			} else if (object && objectId) {
				filteredHistoryStack = historyStack.filter((x) => !(x.object === object && x.objectId === objectId));
			} else if (object === "scorecard" && objectIdList) {
				filteredHistoryStack = historyStack.filter((x) => !objectIdList.includes(x.scorecardId));
			} else if (object && objectIdList) {
				filteredHistoryStack = historyStack.filter(
					(x) => !(x.object === object && objectIdList.includes(x.objectId))
				);
			}
			if (filteredHistoryStack && !isEqual(filteredHistoryStack, historyStack)) {
				setSession({ historyStack: filteredHistoryStack });
			}
			if (homepageFlag) {
				toggleHomepage();
			}
			close();
		},
		[
			checklist,
			clear,
			clearAssociated,
			close,
			elementFlag,
			formContext,
			historyStack,
			homepageFlag,
			listKey,
			method,
			name,
			object,
			objectId,
			objectIdList,
			onTrash,
			queryString,
			reportFlag,
			scorecardId,
			session.scorecardId,
			setSession,
			toastMessage,
			toggleHomepage,
			translate,
			trash,
		]
	);
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>
					{title ||
						(reportFlag
							? translate("manage.layout.deleteReport")
							: `${translate("global.delete")} ${translate(object)}`)}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<HTML>
					{body ||
						(reportFlag
							? translate("edit.deleteReportConfirm")
							: translate("edit.deleteConfirm", { object: name }))}
				</HTML>
			</ModalBody>
			<ModalFooter>
				<Button data-testid="cancel-button" onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button data-testid="delete-button" onClick={trashObject} color="danger">
					{confirmButtonText || translate("global.delete")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

TrashModal.propTypes = propTypes;

export default TrashModal;
