import Block from "../Block";
import Button from "../Button/Button";
import ChecklistCheckAll from "../Checklist/Checklist.Check.All";
import ChecklistProvider from "@clearpoint/providers/ChecklistProvider";
import Column from "../Column";
import FilterInput from "../Filter/Filter.Input";
import FilterProvider, { useFilter } from "@clearpoint/providers/FilterProvider";
import Form from "../Form/Form";
import FormButton from "../Form/Button/Button";
import FormCheckArray from "../Form/CheckArray/CheckArray";
import FormCheckArrayItem from "../Form/CheckArray/CheckArray.Item";
import FormGroup from "../Form/Form.Group";
import Loading from "../Loading";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import Row from "../Row";
import { useCallback, useMemo } from "react";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "../Theme";
import { buildQueryString, removeUndefined } from "@clearpoint/utils";
import { useUserTypeList } from "@clearpoint/hooks";

let propTypes = {
	close: PropTypes.func.isRequired,
	layoutId: PropTypes.number,
	loadingFlag: PropTypes.bool,
	modalVisible: PropTypes.bool,
	object: PropTypes.string,
	objectId: PropTypes.number,
	page: PropTypes.string,
	scorecardId: PropTypes.number,
	userTypeList: PropTypes.array,
};

let BulkSetHomepageModalContent = ({
	close,
	layoutId,
	loadingFlag,
	modalVisible,
	object,
	objectId,
	page,
	scorecardId,
	filteredUserTypeList,
}) => {
	let translate = useTranslate();
	let { clear, set } = useOldQueryStore();
	let { data: userList } = useFilter();
	const query = layoutId
		? buildQueryString({ object, objectId, layoutId, scorecardId })
		: buildQueryString({ object, objectId, scorecardId });
	page = page || query;

	let onSubmit = useCallback(
		(submitValue) => {
			set({
				object: "user",
				queryString: "?homepage",
				data: {
					home: removeUndefined({
						page,
					}),
					users: submitValue.users?.map((userId) => ({ userId })) || [],
				},
			}).then(() => {
				toast.success(translate("toaster.messages.favorites.homepageSet"));
				clear({ object: layoutId ? `${object}Layout` : object, objectId: objectId || layoutId });
				clear({ object: "user" });
				clear({ object: "profile" });
				close();
			});
		},
		[clear, close, layoutId, object, objectId, page, scorecardId, set, translate]
	);
	let defaultValue = useMemo(
		() => ({
			users: userList.filter((x) => x.selected || (x.homepage && page && x.homepage === page)).map((x) => x.userId),
		}),
		[page, userList]
	);
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<Form onSubmit={onSubmit} defaultValue={defaultValue}>
				<ModalHeader>
					<ModalTitle>{translate("myProfile.bulkSetHome")}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FilterInput name="name" filterBy="fullName" placeholder={translate("edit.filterUsers") + "..."} />
					<FormCheckArray name="users">
						{loadingFlag ? (
							<Loading />
						) : (
							filteredUserTypeList.map((userType) => (
								<Block marginBottom={theme.mediumSpace} key={userType.userTypeId}>
									<ChecklistProvider key={userType.userTypeId}>
										<FormGroup
											title={
												<ChecklistCheckAll disableWhenListIsEmptyFlag>{userType.name}</ChecklistCheckAll>
											}
										>
											<Row>
												{userList
													.filter((x) => x.visible && x.userTypeId === userType.userTypeId)
													.map((user) => (
														<Column key={user.userId} small={6}>
															<FormCheckArrayItem
																checklistFlag
																value={user.userId}
																marginBottom={theme.smallSpace}
																defaultCheckedFlag={
																	(user.homepage && page && page.includes(user.homepage)) ||
																	user.selected
																}
															>
																{user.fullName}
															</FormCheckArrayItem>
														</Column>
													))}
											</Row>
										</FormGroup>
									</ChecklistProvider>
								</Block>
							))
						)}
					</FormCheckArray>
				</ModalBody>
				<ModalFooter>
					<Button onClick={close} type="button" color="default">
						{translate("global.cancel")}
					</Button>
					<FormButton color="primary">{translate("global.save")}</FormButton>
				</ModalFooter>
			</Form>
		</Modal>
	);
};
BulkSetHomepageModalContent.propTypes = propTypes;

let BulkSetHomepageModal = ({ layoutId, object, objectId, ...props }) => {
	let { get } = useOldQueryStore();
	let userList = get({ object: "user" });
	let userListWithAccess = useMemo(() => userList?.filter((x) => x.access !== "No Access"), [userList]);
	let userTypeList = useUserTypeList();
	let filteredUserTypeList = useMemo(() => {
		if (!userListWithAccess) return [];
		return userTypeList.filter((userType) =>
			userListWithAccess.some((user) => user.userTypeId === userType.userTypeId)
		);
	}, [userListWithAccess, userTypeList]);
	let loadingFlag = userList === undefined;
	return (
		<FilterProvider data={userListWithAccess}>
			<BulkSetHomepageModalContent
				layoutId={layoutId}
				loadingFlag={loadingFlag}
				object={object}
				objectId={objectId}
				filteredUserTypeList={filteredUserTypeList}
				{...props}
			/>
		</FilterProvider>
	);
};

export default BulkSetHomepageModal;
