// NOTE: Add alias and lookup here.
// Add icon title to en_us.json ("icon.[name]: title")
let iconDictionary = {
	accessibility: ["fal", "universal-access"],
	accomplishedGoal: "circle-check",
	actionItem: ["fal", "check"],
	actionItems: ["fal", "check"],
	activate: ["fal", "plus"],
	activeAlerts: ["fal", "bell-exclamation"],
	add: ["fal", "plus"],
	added: ["fal", "plus"],
	addMultiple: ["fal", "plus-square"],
	addUser: ["fal", "plus"],
	addUsers: "users",
	adminOptions: ["fal", "cog"],
	ai: ["fal", "wand-magic-sparkles"],
	alert: ["fal", "envelope"],
	alertInbox: ["fal", "bell"],
	alerts: ["fal", "bell"],
	alignLeft: ["fal", "align-left"],
	alignTop: ["fal", "align-left"],
	ansoff: ["fal", "circle-quarters"],
	apiKey: ["fal", "key"],
	apiKeyKey: ["fal", "key"],
	apiKeys: "cogs",
	archive: ["fal", "clock"],
	assign: ["fal", "arrow-right"],
	attachment: ["fal", "paperclip"],
	attachments: ["fal", "paperclip"],
	auditLog: ["fal", "undo"],
	automation: ["fab", "superpowers"],
	automateUpdates: ["fal", "bolt-auto"],
	balancedScorecard: ["fal", "chart-mixed-up-circle-currency"],
	bell: ["fal", "bell"],
	blacklist: ["fal", "ban"],
	blockedGoal: "ban",
	blueOcean: ["fal", "water-arrow-up"],
	briefingBook: ["fal", "file-pdf"],
	briefingBooks: ["fal", "archive"],
	"bulk-changes": ["fal", "bucket"],
	bullsEyePointer: ["fal", "bullseye-pointer"],
	calculated: ["fal", "calculator"],
	calculatedField: ["fal", "calculator"],
	calculatedFields: ["fal", "calculator"],
	calculatedSimple: ["fa", "calculator-simple"],
	calendar: ["fal", "calendar-alt"],
	cancelChange: ["fal", "arrow-down-up-across-line"],
	categories: ["fal", "bookmark"],
	category: ["fal", "bookmark"],
	caution: ["fal", "diamond-exclamation"],
	changeOrientation: "retweet",
	chart: ["fal", "chart-column"],
	chartData: ["fal", "table"],
	charts: ["fal", "chart-column"],
	chartType: ["fal", "chart-column"],
	chartUser: ["fal", "chart-user"],
	checkbox: "check-square",
	checkboxOutline: ["fal", "check-square"],
	child: ["fal", "level-down-alt"],
	childInitiatives: ["fal", "industry"],
	childMeasures: ["fal", "chart-column"],
	children: ["fal", "level-down-alt"],
	cleaned: ["fal", "times"],
	"clearpoint-api": ["fal", "plug"],
	clipboard: ["fal", "clipboard"],
	close: ["fal", "times"],
	closeDiscussionBar: ["fal", "arrow-right"],
	closeHelp: ["fal", "ban"],
	collaborators: ["fal", "users"],
	collapse: ["fal", "chevron-up"],
	collapseChart: ["fal", "chevron-up"],
	collapsed: ["fal", "chevron-left"],
	collapsedRight: ["fal", "chevron-right"],
	colorPicker: ["fal", "eye-dropper"],
	comment: ["fal", "comments"],
	commentsQuestion: ["fal", "comments-question"],
	completed: ["fal", "check"],
	complexGear: ["fal", "gear-complex-code"],
	contract: ["fal", "minus"],
	copy: ["fal", "copy"],
	copyForward: ["fal", "redo"],
	createdGoal: "circle-play",
	currentScorecard: ["fa", "fa-angles-right"],
	custom: ["fal", "code"],
	customField: ["fal", "code"],
	customFields: ["fal", "code"],
	customStyles: ["fal", "paint-brush"],
	dashboardLayout: ["fal", "th"],
	dashboard: ["fasl", "browser"],
	"data-loader": ["fal", "cloud-upload-alt"],
	defaultFields: ["fal", "edit"],
	defaultSeries: ["fal", "chart-line"],
	delete: ["fal", "trash-alt"],
	deleted: ["fal", "times"],
	department: ["fal", "users"],
	detailLayout: ["fal", "th"],
	directConnection: ["fal", "handshake"],
	disableSound: ["fal", "volume-off"],
	dismissAlerts: ["fal", "bell-slash"],
	district: ["fal", "building"],
	document: ["fal", "archive"],
	documentLibrary: ["fal", "archive"],
	documents: ["fal", "archive"],
	download: ["fal", "download"],
	drag: ["fal", "grip-dots-vertical"],
	dropdown: ["fal", "angle-down"],
	dropdownClose: ["fal", "angle-up"],
	dropdownLeft: ["fal", "chevron-left"],
	dropdownRight: ["fal", "chevron-right"],
	duplicated: ["fal", "copy"],
	edit: ["fal", "pencil"],
	edited: ["fal", "pencil-alt"],
	editMultiple: ["fal", "pen-to-square"],
	editReport: ["fal", "pen-to-square"],
	elements: ["fal", "cubes"],
	ellipsisV: "fa-ellipsis-v",
	email: ["fal", "envelope"],
	emailSupport: ["fal", "envelope"],
	emptied: ["fal", "times"],
	enableSound: ["fal", "volume-up"],
	energy: ["fal", "lightbulb-on"],
	eos: ["fal", "star-of-life"],
	error: ["fal", "times"],
	excel: ["fal", "file-excel"],
	excelFile: ["fal", "file-excel"],
	exceptionReport: ["fal", "triangle-exclamation"],
	expand: ["fal", "plus"],
	expandChart: ["fal", "chevron-down"],
	expanded: ["fal", "chevron-down"],
	expandSelect: ["fal", "caret-down"],
	exportCalendar: ["fal", "calendar-arrow-down"],
	"fa-bell": ["fal", "bell"],
	"fa-briefcase": ["fal", "briefcase"],
	"fa-file-excel-o": ["fal", "file-excel"],
	"fa-file-image-o": ["fal", "image"],
	"fa-file-o": ["fal", "file"],
	"fa-file-pdf-o": ["fal", "file-pdf"],
	"fa-file-powerpoint-o": ["fal", "file-powerpoint"],
	"fa-file-word-o": ["fal", "file-word"],
	"fa-picture-o": ["fal", "image"],
	failed: ["fal", "times"],
	favorite: "star",
	favoriteOutline: ["fal", "star"],
	fieldLabels: ["fal", "tags"],
	file: ["fal", "file"],
	fileExport: ["fal", "file-arrow-down"],
	filter: ["fal", "filter"],
	fiscalYear: ["fal", "calendar-check"],
	fit: ["fal", "arrows-alt"],
	framework: ["fal", "ruler-triangle"],
	goal: ["fal", "trophy"],
	goalOwner: ["fal", "eye"],
	goals: ["fal", "trophy"],
	grid: ["fal", "table-cells"],
	gridLayout: ["fal", "th"],
	group: ["fal", "users"],
	healthcare: ["fal", "hospital"],
	heart: ["fal", "heart"],
	help: ["fal", "circle-question"],
	hidden: ["fal", "ban"],
	hiddenPod: "minus-circle",
	hideTabs: ["fal", "window-restore"],
	history: ["fal", "history"],
	home: ["fal", "home"],
	homeOutline: ["fal", "home"],
	html: ["fal", "briefcase"],
	image: ["fal", "image"],
	initiative: ["fal", "industry"],
	initiativeMeasure: ["fal", "chart-column"],
	initiativeMeasures: ["fal", "chart-column"],
	initiativeMilestone: ["fal", "indent"],
	initiativeMilestones: ["fal", "indent"],
	initiatives: ["fal", "industry"],
	inProgressGoal: "circle-half-stroke",
	insert: ["fal", "bolt"],
	integration: ["fal", "handshake"],
	integrations: ["fal", "cogs"],
	intercomChat: ["fal", "comment"],
	inUse: ["fal", "check"],
	ipAddressRestrictions: ["fal", "ban"],
	kebab: ["fal", "ellipsis-vertical"],
	label: ["fal", "tags"],
	laugh: ["fal", "face-laugh-beam"],
	layout: ["fal", "th"],
	link: ["fal", "external-link-alt"],
	linkMap: ["fal", "sitemap"],
	linkTag: ["fal", "link"],
	list: ["fal", "list"],
	loading: ["fal", "circle-notch"],
	locked: ["fal", "lock"],
	login: ["fal", "key"],
	logout: ["fal", "sign-out-alt"],
	maintainDataIntegrity: ["fal", "check-double"],
	manage: ["fal", "gear"],
	manageElements: ["fal", "cubes"],
	manageFiscalYears: ["fal", "calendar"],
	manageLayouts: ["fal", "table"],
	manageMasterPeriods: ["fal", "university"],
	managePeriodGroups: ["fal", "calendar-check"],
	managePeriods: ["fal", "calendar"],
	manager: ["fal", "people-roof"],
	manageReportingFrequencies: ["fal", "calendar-check"],
	manageUserGroups: ["fal", "users"],
	manageUsers: ["fal", "user"],
	manageWorkspaces: ["fal", "objects-column"],
	map: ["fal", "map"],
	markAllRead: ["fal", "eye-slash"],
	markComplete: ["fal", "check-square"],
	markIncomplete: ["fal", "square"],
	markRead: ["fal", "eye-slash"],
	markUnread: ["fal", "eye"],
	maximize: ["fal", "square"],
	measure: ["fal", "chart-column"],
	measureInitiative: ["fal", "industry"],
	measureInitiatives: ["fal", "industry"],
	measureLibrary: ["fal", "university"],
	measures: ["fal", "chart-column"],
	measureSeries: ["fal", "chart-column"],
	menu: ["fal", "bars"],
	menuAndElementNames: ["fal", "list-ul"],
	milestone: ["fal", "indent"],
	milestones: ["fal", "indent"],
	moveDown: ["fal", "arrow-down"],
	moveLeft: ["fal", "arrow-left"],
	moveRight: ["fal", "arrow-right"],
	moveToBottom: ["fal", "sort-down"],
	moveToTop: ["fal", "sort-up"],
	moveUp: ["fal", "arrow-up"],
	municipality: ["fal", "mountain-city"],
	myClearPoint: ["fal", "star"],
	myFavorites: ["fal", "star"],
	myMentions: ["fal", "at"],
	myNotifications: ["fal", "bell"],
	myApprovals: ["fal", "thumbs-up"],
	myOKRs: ["fal", "trophy-star"],
	myScorecard: ["fal", "user"],
	myUpdates: ["fal", "pencil-alt"],
	navigation: ["fal", "signs-post"],
	next: ["fal", "chevron-right"],
	nextArrow: ["fal", "arrow-right"],
	noAccess: ["fal", "ban"],
	nonprofit: ["fal", "ribbon"],
	notification: ["fal", "bell"],
	notifications: ["fal", "bell"],
	numberFormat: ["fal", "money-bill-alt"],
	numberFormats: ["fal", "money-bill-alt"],
	objective: ["fal", "cube"],
	objectives: ["fal", "cube"],
	offTarget: ["fal", "times"],
	onTarget: ["fal", "check"],
	organization: ["fal", "building-flag"],
	organizationAndSecurity: ["fal", "building"],
	owner: ["fal", "user"],
	package: ["fal", "cloud-upload-alt"],
	parent: ["fal", "level-up-alt"],
	parents: ["fal", "level-up-alt"],
	paste: ["fal", "paste"],
	payload: ["fal", "file"],
	pdf: ["fal", "file-pdf"],
	performanceReportAutomation: ["fal", "square-kanban"],
	period: ["fal", "calendar"],
	periodGroup: ["fal", "calendar-check"],
	pest: ["fal", "sun-cloud"],
	pieChart: ["fal", "chart-pie"],
	pin: ["fal", "thumbtack"],
	planhat: ["fal", "circle-info"],
	plusSquare: ["fal", "plus-square"],
	podStyle: ["fal", "paint-brush"],
	porter: ["fal", "square-5"],
	powerpoint: ["fal", "file-powerpoint"],
	preview: ["fal", "tv"],
	previous: ["fal", "chevron-left"],
	previousArrow: ["fal", "arrow-left"],
	priority: ["fal", "flag"],
	projectEvaluation: ["fal", "industry"],
	publish: ["fal", "cloud-arrow-up"],
	purged: ["fal", "trash-alt"],
	questionMark: ["fal", "question"],
	queued: ["fal", "plus"],
	reactedExclamation: ["far", "diamond-exclamation"],
	reactedHeart: ["far", "heart"],
	reactedLaugh: ["far", "face-laugh-beam"],
	reactedQuestionMark: ["far", "question"],
	reactedThumbsDown: ["far", "thumbs-down"],
	reactedThumbsUp: ["far", "thumbs-up"],
	recalc: ["fal", "redo-alt"],
	recalculate: ["fal", "refresh"],
	recalculation: ["fal", "calculator"],
	recalculateMeasure: ["fal", "calculator"],
	recommendEngine: ["fal", "bolt"],
	recycleBin: ["fal", "recycle"],
	reference: ["fal", "link"],
	refresh: ["fal", "redo-alt"],
	regenerate: "redo",
	reminder: ["fal", "envelope"],
	remove: ["fal", "times"],
	removeUser: ["fal", "times"],
	repeat: ["fal", "redo"],
	reply: ["fal", "mail-reply"],
	report: ["fal", "table"],
	reportingPeriod: ["fal", "calendar"],
	reportOptions: ["fal", "print"],
	reports: ["fal", "table"],
	reportStyle: ["fal", "paint-brush"],
	republish: ["fal", "arrows-rotate"],
	resizeIndicator: ["far", "tally-2"],
	resolve: ["fal", "circle-exclamation-check"],
	restartChat: ["far", "arrow-up-to-line"],
	restore: ["fal", "clone"],
	revisionHistory: ["fal", "clock"],
	reviewApproval: ["fal", "arrow-down-arrow-up"],
	risk: ["fal", "flag"],
	risks: ["fal", "flag"],
	rotate: ["fal", "rotate"],
	run: ["fal", "rocket"],
	runIntegration: ["fal", "play"],
	runScript: ["fal", "play"],
	save: ["fal", "save"],
	saveCheck: ["fal", "check"],
	saveTime: ["fal", "hourglass-clock"],
	saving: ["fal", "circle-notch"],
	schedule: ["fal", "calendar-alt"],
	schedules: ["fal", "calendar-alt"],
	schedulesCalendar: ["fal", "calendar-alt"],
	schedulesCopyForward: ["fal", "redo-alt"],
	scorecard: ["fal", "sitemap"],
	scorecardHome: ["fal", "table-layout"],
	scorecardNavCircle: ["fa", "circle"],
	scorecards: ["fal", "sitemap"],
	script: ["fal", "file-code"],
	scripting: ["fal", "file-code"],
	search: ["fal", "search"],
	send: ["fal", "paper-plane"],
	sendReminders: ["fal", "bell"],
	series: ["fal", "chart-line"],
	settings: ["fal", "gear"],
	showTabs: ["fal", "window-restore"],
	smile: ["fal", "face-smile"],
	snapshots: ["fal", "clock"],
	sort: ["fal", "sort"],
	sortAscending: ["fal", "sort-up"],
	sortDescending: ["fal", "sort-down"],
	sortDropdown: ["fal", "sort-alpha-down"],
	spinner: ["fal", "spinner"],
	square: ["fa", "square"],
	stakeholders: ["fal", "users"],
	start: ["fal", "play"],
	started: ["fal", "play"],
	stateAgency: ["fal", "landmark-flag"],
	status: ["fal", "thumbs-up"],
	statusIndicators: ["fal", "thumbs-up"],
	strategicHorizons: ["fal", "sunrise"],
	portal: ["fal", "hand-wave"],
	summarize: ["fal", "arrow-up-wide-short"],
	supportCenter: ["fal", "circle-question"],
	swot: ["fal", "arrows-to-circle"],
	table: ["fal", "table"],
	tableStyle: ["fal", "paint-brush"],
	tag: ["fal", "bookmark"],
	tags: ["fal", "bookmark"],
	template: ["fal", "book"],
	thumbsDown: ["fal", "thumbs-down"],
	thumbsUp: ["fal", "thumbs-up"],
	trash: ["fal", "trash-alt"],
	trashPage: ["fal", "trash-alt"],
	treeExplorer: ["fal", "diagram-subtask"],
	diagramCells: ["fal", "diagram-cells"],
	tutorial: ["fal", "route"],
	undelete: ["fal", "undo"],
	unarchive: ["fal", "undo"],
	undeleted: ["fal", "undo"],
	university: ["fal", "building-columns"],
	unlink: ["fal", "unlink"],
	unlock: ["fal", "unlock"],
	unpublish: ["fal", "arrow-down-to-line"],
	unresolve: ["far", "life-ring"],
	updated: ["fal", "edit"],
	upload: ["fal", "upload"],
	user: ["fal", "user"],
	userAdd: ["fal", "user-plus"],
	userGroup: ["fal", "users"],
	valueDisciplines: ["fal", "value-absolute"],
	vrio: ["fal", "diamond-exclamation"],
	v2mom: ["fal", "arrow-progress"],
	webhook: ["fal", "feed"],
	webhooks: "rss",
	with: ["fal", "arrows-alt"],
	word: ["fal", "file-word"],
	workflow: "exchange-alt",
	workflowEmail: ["fal", "envelope"],
	workflows: ["fal", "exchange-alt"],
	workspace: ["fal", "exchange-alt"],
	zapier: ["fal", "z"],
	zoomIn: ["fal", "search-plus"],
	zoomOut: ["fal", "search-minus"],
};
export default iconDictionary;
