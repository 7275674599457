import { useMemo } from "react";
import { useOldSession } from "@clearpoint/old-session/index";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { getEditStatusDisplayFlag, toBoolean } from "@clearpoint/utils";
import { useFormatDate } from "@clearpoint/hooks";

let useDataProps = ({ cell, objectData, columnIndex }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { exportFlag } = useOldSession().session;
	let { get } = useOldQueryStore();
	let dataProps = useMemo(() => {
		if (!exportFlag || !objectData) return {};
		let statusList = get({ object: "status" });
		let options = get({ object: "options" });
		let { fieldId, hideRepeated, hideStatus, object, objectId, periodId, statusDisplay, template, type, updateMode } =
			cell;
		let editStatusDisplayFlag = getEditStatusDisplayFlag(cell);
		let strikethroughFlag = options?.options?.strikeComplete;
		let strikethrough = strikethroughFlag && objectData.completed && fieldId === "name";
		updateMode = type ? type === "update" : updateMode;
		if (hideStatus) statusDisplay = "hidden";
		if (template === "status" && statusDisplay !== "hidden") statusDisplay = "icon";
		if (fieldId === "name" && statusDisplay !== "hidden") statusDisplay = statusDisplay || "icon";
		if (fieldId === "lastEditedBy") fieldId = "updatedBy";
		if (fieldId === "lastEdited") fieldId = "updatedDate";
		let { calculated, format, measureId, numberFormat, treeLevel } = objectData || {};
		let updateData =
			fieldId === "seriesData"
				? objectData.seriesData.find((x) => x.periodId === periodId)
				: objectData?.status?.[periodId];
		let calculatedData = updateData?.calculatedValues?.[fieldId];
		let customFieldData = objectData._customFields?.[periodId]?.[fieldId];
		updateData = calculatedData || customFieldData || updateData;
		periodId = updateData?.periodId || periodId;
		let {
			customFieldType,
			displayPeriodId,
			numberFormat: updateNumberFormat,
			picklist,
			statusId,
			value,
		} = updateData || {};
		if (value === undefined) value = updateData?.[fieldId];
		if (value === undefined) value = objectData[fieldId];
		if (value === undefined) value = calculatedData;
		if (fieldId === "updatedBy") fieldId = "lastEditedBy";
		if (fieldId === "updatedDate") fieldId = "lastEdited";
		numberFormat = updateNumberFormat || numberFormat;
		let statusColor = null;
		if (editStatusDisplayFlag && statusDisplay === "color" && statusList && statusId) {
			statusColor = statusList.find((x) => x.statusId === statusId)?.statusColor;
		}
		let seriesMismatchFlag = displayPeriodId && displayPeriodId !== periodId;
		let hideRepeatedFlag = hideRepeated && seriesMismatchFlag;
		value = hideRepeatedFlag ? undefined : value;
		let fieldTypeLookup = {
			analysis: "html",
			chart: "chart",
			comments: "html",
			completedDate: "date",
			endDate: "date",
			lastEdited: "date",
			lastUpdated: "date",
			percentComplete: "percent",
			recommendations: "html",
			startDate: "date",
			today: "date",
		};
		let fieldType =
			calculatedData || fieldId === "seriesData"
				? "number"
				: template === "status"
				? false
				: fieldTypeLookup[fieldId] || customFieldType;
		if (fieldType === "boolean") {
			let yes = translate("global.displayYes");
			let no = translate("global.displayNo");
			picklist = [yes, no];
			value = toBoolean(value) ? yes : no;
		}
		if ([fieldId, fieldType].includes("completed")) {
			let completed = translate("displayDetail.fields.boolean.completed");
			let notCompleted = translate("displayDetail.fields.boolean.notCompleted");
			picklist = [completed, notCompleted];
			value = toBoolean(value) ? completed : notCompleted;
		}
		if ([fieldId, fieldType].includes("completedDate")) {
			value = objectData.completed ? value : null;
		}
		if (["startDate", "endDate", "completedDate", "today"].includes(fieldId)) value = formatDate(value, "apiDate");
		if (numberFormat) numberFormat = JSON.stringify(numberFormat);
		if (picklist) picklist = JSON.stringify(picklist);
		let dataProps = {
			"data-calculated": calculated || !!calculatedData,
			"data-col-index": columnIndex,
			"data-field-id": fieldId === "seriesData" ? "value" : fieldId,
			"data-field-type": fieldType,
			"data-format": ["percentComplete", "variance", "elapsedPercentComplete"].includes(fieldId)
				? "percent"
				: format,
			"data-measure-id": measureId,
			"data-number-format": numberFormat,
			"data-object": object,
			"data-object-id": objectId,
			"data-period-id": periodId,
			"data-picklist": picklist,
			"data-strikethrough": strikethrough,
			"data-status-color": statusColor,
			"data-series-mismatch": seriesMismatchFlag,
			"data-status-display": statusDisplay,
			"data-status-id": statusId,
			"data-tree-level": fieldId === "name" && treeLevel,
			"data-update-mode": updateMode,
			"data-value": value,
		};
		for (let key in dataProps) {
			if (dataProps[key] === false) delete dataProps[key];
		}
		return dataProps;
	}, [exportFlag, objectData, get, cell, formatDate, columnIndex, translate]);

	return dataProps;
};
export default useDataProps;
