import Select from "./FormSelect";
import { useMemo } from "react";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";

let propTypes = {
	filter: PropTypes.func,
};

let SelectAccessType = ({ filter, ...props }) => {
	let translate = useTranslate();

	let accessList = useMemo(
		() => [
			{ value: 4, label: translate("userTypes.user7") }, //no access
			{ value: 3, label: translate("userTypes.user4") }, //browser
			{ value: 2, label: translate("userTypes.user3") }, // updater
			{ value: 1, label: translate("userTypes.user2") }, // editor
			{ value: 5, label: translate("userTypes.user9") }, // scorecard admin
		],
		[translate]
	);

	let options = useMemo(() => {
		let optionsArray = [...accessList];

		if (filter) {
			optionsArray = optionsArray.filter(filter);
		}

		return optionsArray;
	}, [filter, accessList]);

	return <Select options={options} {...props} />;
};

SelectAccessType.propTypes = propTypes;

export default SelectAccessType;
