import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAlignLeft as falAlignLeft,
	faAngleDown as falAngleDown,
	faAngleUp as falAngleUp,
	faArchive as falArchive,
	faArrowDown as falArrowDown,
	faArrowDownArrowUp as falArrowDownArrowUp,
	faArrowDownToLine as falArrowDownToLine,
	faArrowDownUpAcrossLine as falArrowDownUpAcrossLine,
	faArrowLeft as falArrowLeft,
	faArrowProgress as falArrowProgress,
	faArrowRight as falArrowRight,
	faArrowsAlt as falArrowsAlt,
	faArrowsToCircle as falArrowsToCircle,
	faArrowUp as falArrowUp,
	faArrowUpWideShort as falArrowUpWideShort,
	faAt as falAt,
	faBan as falBan,
	faBars as falBars,
	faBell as falBell,
	faBellExclamation as falBellExclamation,
	faBellSlash as falBellSlash,
	faBolt as falBolt,
	faBoltAuto as falBoltAuto,
	faBook as falBook,
	faBookmark as falBookmark,
	faBriefcase as falBriefcase,
	faBucket as falBucket,
	faBuilding as falBuilding,
	faBuildingColumns as falBuildingColumns,
	faBuildingFlag as falBuildingFlag,
	faBullseyePointer as falBullseyePointer,
	faCalculator as falCalculator,
	faCalendar as falCalendar,
	faCalendarAlt as falCalendarAlt,
	faCalendarArrowDown as falCalendarArrowDown,
	faCalendarCheck as falCalendarCheck,
	faCaretDown as falCaretDown,
	faChartColumn as falChartColumn,
	faChartLine as falChartLine,
	faChartMixedUpCircleCurrency as falChartMixedUpCircleCurrency,
	faChartPie as falChartPie,
	faChartUser as falChartUser,
	faCheck as falCheck,
	faCheckDouble as falCheckDouble,
	faCheckSquare as falCheckSquare,
	faChevronDown as falChevronDown,
	faChevronLeft as falChevronLeft,
	faChevronRight as falChevronRight,
	faChevronUp as falChevronUp,
	faCircleExclamationCheck as falCircleExclamationCheck,
	faCircleInfo as falCircleInfo,
	faCircleNotch as falCircleNotch,
	faCircleQuarters as falCircleQuarters,
	faCircleQuestion as falCircleQuestion,
	faClipboard as falClipboard,
	faClock as falClock,
	faClone as falClone,
	faCloudArrowUp as falCloudArrowUp,
	faCloudUploadAlt as falCloudUploadAlt,
	faCode as falCode,
	faCog as falCog,
	faCogs as falCogs,
	faComment as falComment, // do not delete
	faComments as falComments,
	faCommentsQuestion as falCommentsQuestion,
	faCopy as falCopy,
	faCube as falCube,
	faCubes as falCubes,
	faDiagramCells as falDiagramCells,
	faDiagramSubtask as falDiagramSubtask,
	faDiamondExclamation as falDiamondExclamation,
	faDownload as falDownload,
	faEdit as falEdit,
	faEllipsisVertical as falEllipsisVertical,
	faEnvelope as falEnvelope,
	faExchangeAlt as falExchangeAlt,
	faExternalLinkAlt as falExternalLinkAlt,
	faEye as falEye,
	faEyeDropper as falEyeDropper,
	faEyeSlash as falEyeSlash,
	faFaceLaughBeam as falFaceLaughBeam,
	faFaceSmile as falFaceSmile,
	faFeed as falFeed,
	faFile as falFile,
	faFileArrowDown as falFileArrowDown,
	faFileCode as falFileCode,
	faFileExcel as falFileExcel,
	faFilePdf as falFilePdf,
	faFilePowerpoint as falFilePowerpoint,
	faFileWord as falFileWord,
	faFilter as falFilter,
	faFlag as falFlag,
	faGear as falGear,
	faGearComplexCode as falGearComplexCode,
	faGripDotsVertical as falGripDotsVertical,
	faHandshake as falHandshake,
	faHandWave as falHandWave,
	faHeart as falHeart,
	faHistory as falHistory,
	faHome as falHome,
	faHospital as falHospital,
	faHourglassClock as falHourglassClock,
	faHouse as falHouse,
	faImage as falImage,
	faIndent as falIndent,
	faIndustry as falIndustry,
	faKey as falKey,
	faLandmarkFlag as falLandmarkFlag,
	faLevelDownAlt as falLevelDownAlt,
	faLevelUpAlt as falLevelUpAlt,
	faLightbulbOn as falLightbulbOn,
	faLink as falLink,
	faList as falList,
	faListUl as falListUl,
	faLock as falLock,
	faMailReply as falMailReply,
	faMap as falMap,
	faMinus as falMinus,
	faMoneyBillAlt as falMoneyBillAlt,
	faMountainCity as falMountainCity,
	faObjectsColumn as falObjectsColumn,
	faPaintBrush as falPaintBrush,
	faPaperclip as falPaperclip,
	faPaperPlane as falPaperPlane,
	faPaste as falPaste,
	faPencil as falPencil,
	faPencilAlt as falPencilAlt,
	faPenToSquare as falPenToSquare,
	faPeopleRoof as falPeopleRoof,
	faPlay as falPlay,
	faPlug as falPlug,
	faPlus as falPlus,
	faPlusSquare as falPlusSquare,
	faPrint as falPrint,
	faQuestion as falQuestion,
	faRecycle as falRecycle,
	faRedo as falRedo,
	faRedoAlt as falRedoAlt,
	faRefresh as falRefresh,
	faRibbon as falRibbon,
	faRocket as falRocket,
	faRotate as falRotate,
	faRoute as falRoute,
	faRulerTriangle as falRulerTriangle,
	faSave as falSave,
	faSearch as falSearch,
	faSearchMinus as falSearchMinus,
	faSearchPlus as falSearchPlus,
	faSignOutAlt as falSignOutAlt,
	faSignsPost as falSignsPost,
	faSitemap as falSitemap,
	faSort as falSort,
	faSortAlphaDown as falSortAlphaDown,
	faSortDown as falSortDown,
	faSortUp as falSortUp,
	faSpinner as falSpinner,
	faSquare as falSquare,
	faSquare5 as falSquare5,
	faSquareKanban as falSquareKanban,
	faStar as falStar,
	faStarOfLife as falStarOfLife,
	faSunCloud as falSunCloud,
	faSunrise as falSunrise,
	faTable as falTable,
	faTableCells as falTableCells,
	faTableLayout as falTableLayout,
	faTags as falTags,
	faTh as falTh,
	faThumbsDown as falThumbsDown,
	faThumbsUp as falThumbsUp,
	faThumbtack as falThumbtack,
	faTimes as falTimes,
	faTrashAlt as falTrashAlt,
	faTriangleExclamation as falTriangleExclamation,
	faTrophy as falTrophy,
	faTrophyStar as falTrophyStar,
	faTv as falTv,
	faUndo as falUndo,
	faUniversalAccess as falUniversalAccess,
	faUniversity as falUniversity,
	faUnlink as falUnlink,
	faUnlock as falUnlock,
	faUpload as falUpload,
	faUser as falUser,
	faUserPlus as falUserPlus,
	faUsers as falUsers,
	faValueAbsolute as falValueAbsolute,
	faVolumeOff as falVolumeOff,
	faVolumeUp as falVolumeUp,
	faWandMagicSparkles as falWandMagicSparkles,
	faWaterArrowUp as falWaterArrowUp,
	faWindowRestore as falWindowRestore,
	faZ as falZ} from "@fortawesome/pro-light-svg-icons";
import { faBrowser as faslBrowser } from "@fortawesome/sharp-light-svg-icons";

library.add(
	falAlignLeft,
	falAngleDown,
	falAngleUp,
	falArchive,
	falArrowDown,
	falArrowDownToLine,
	falArrowLeft,
	falArrowProgress,
	falArrowRight,
	falArrowUp,
	falArrowsAlt,
	falArrowsToCircle,
	falArrowUpWideShort,
	falAt,
	falBan,
	falBars,
	falBell,
	falBellExclamation,
	falBellSlash,
	falBolt,
	falBoltAuto,
	falBook,
	falBookmark,
	falBriefcase,
	falBucket,
	falBuilding,
	falBuildingColumns,
	falBuildingFlag,
	falBullseyePointer,
	falCalculator,
	falCalendar,
	falCalendarAlt,
	falCalendarArrowDown,
	falCalendarCheck,
	falCaretDown,
	falChartColumn,
	falChartLine,
	falChartPie,
	falChartUser,
	falChartMixedUpCircleCurrency,
	falCheck,
	falCheckDouble,
	falCheckSquare,
	falChevronDown,
	falChevronLeft,
	falChevronRight,
	falChevronUp,
	falCircleExclamationCheck,
	falCircleInfo,
	falCircleNotch,
	falCircleQuarters,
	falCircleQuestion,
	falClipboard,
	falClock,
	falClone,
	falCloudArrowUp,
	falCloudUploadAlt,
	falCode,
	falCog,
	falCogs,
	falComment, // do not delete (or at least make sure you've given "intercomChat" in the icon dictionary a different icon before you delete this one)
	falComments,
	falCommentsQuestion,
	falCopy,
	falCube,
	falCubes,
	falDiagramCells,
	falDiagramSubtask,
	falDiamondExclamation,
	falDownload,
	falEdit,
	falEllipsisVertical,
	falEnvelope,
	falExchangeAlt,
	falExchangeAlt,
	falExternalLinkAlt,
	falEye,
	falEyeDropper,
	falEyeSlash,
	falFaceSmile,
	falFaceLaughBeam,
	falFeed,
	falFile,
	falFileCode,
	falFileExcel,
	falFileArrowDown,
	falFilePdf,
	falFilePowerpoint,
	falFileWord,
	falFilter,
	falFlag,
	falGear,
	falGearComplexCode,
	falGripDotsVertical,
	falHandWave,
	falHandshake,
	falHospital,
	falHeart,
	falHistory,
	falHome,
	falHourglassClock,
	falHouse,
	falImage,
	falIndent,
	falIndustry,
	falKey,
	falLandmarkFlag,
	falLevelDownAlt,
	falLevelUpAlt,
	falLightbulbOn,
	falLink,
	falList,
	falListUl,
	falLock,
	falMailReply,
	falMap,
	falMinus,
	falMoneyBillAlt,
	falMountainCity,
	falObjectsColumn,
	falPaintBrush,
	falPaperPlane,
	falPaperclip,
	falPaste,
	falPenToSquare,
	falPencil,
	falPencilAlt,
	falPeopleRoof,
	falPlay,
	falPlug,
	falPlus,
	falPlusSquare,
	falPrint,
	falQuestion,
	falRecycle,
	falRedo,
	falRedoAlt,
	falRefresh,
	falRibbon,
	falRocket,
	falRotate,
	falRoute,
	falRulerTriangle,
	falSave,
	falSearch,
	falSearchMinus,
	falSearchPlus,
	falSignOutAlt,
	falSignsPost,
	falSitemap,
	falSort,
	falSortAlphaDown,
	falSortDown,
	falSortUp,
	falSpinner,
	falSquare,
	falSquareKanban,
	falSquare5,
	falStar,
	falStarOfLife,
	falSunCloud,
	falSunrise,
	falTable,
	falTableCells,
	falTableLayout,
	falTags,
	falTh,
	falThumbsDown,
	falThumbsUp,
	falThumbtack,
	falTimes,
	falTrashAlt,
	falTriangleExclamation,
	falTrophy,
	falTrophyStar,
	falTv,
	falUndo,
	falUniversalAccess,
	falUniversity,
	falUnlink,
	falUnlock,
	falUpload,
	falUser,
	falUserPlus,
	falUsers,
	falValueAbsolute,
	falVolumeOff,
	falVolumeUp,
	falWandMagicSparkles,
	falWaterArrowUp,
	falWindowRestore,
	falZ,
	faslBrowser,
	falArrowDownUpAcrossLine,
	falArrowDownArrowUp
);
