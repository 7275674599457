let accessibeUrl = "https://acsbap.com/api/v1/assets/js/acsb.js";

let initializeAccessiBe = (AccessiBe) => {
	if (AccessiBe)
		AccessiBe.init({
			feedbackLink: "",
			focusInnerColor: "#146FF8",
			focusOuterColor: "#ff7216",
			footerHtml: "ClearPoint Strategy Accessibility Options",
			headerHtml: "",
			hideMobile: false,
			hideTrigger: false,
			keyNavStrong: false,
			leadColor: "#27245e",
			mobile: {
				triggerOffsetX: 0,
				triggerOffsetY: 0,
				triggerPositionX: "right",
				triggerPositionY: "bottom",
				triggerRadius: "0",
				triggerSize: "small",
			},
			position: "right",
			showAllActions: false,
			size: "big",
			statementLink: "",
			triggerColor: "#23c6c8",
			triggerIcon: "wheel_chair5",
			triggerOffsetX: 15,
			triggerOffsetY: 17,
			triggerPositionX: "right",
			triggerPositionY: "top",
			triggerRadius: "4px",
			triggerSize: "small",
			usefulLinks: {},
			wlbl: "accessiBe",
		});
};

let initializeAccessiBeString = initializeAccessiBe.toString();

let getAccessibilityScripts = () => {
	const accessibleScript = document.createElement("script", { type: "text/javascript" }, { src: accessibeUrl });
	const initializeAccessiBeScript = document.createElement("script", { type: "text/javascript" });
	initializeAccessiBeScript.textContent = `(${initializeAccessiBeString})(window.AccessiBe);`;
	return [accessibleScript, initializeAccessiBeScript];
};

export default getAccessibilityScripts;
