import EditPodModalContent from "./EditPodModalContent";
import ModalWindow from "@clearpoint/old-theme/ModalWindow/ModalWindow";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

let propTypes = {
	close: PropTypes.func,
	modalVisible: PropTypes.bool,
	name: PropTypes.string,
};

let EditPodModal = ({ close, modalWindowVisible, name }) => {
	let { setFormValue } = useFormContext();

	let object = useFormValue("object");
	let podInForm = useFormValue(name);

	let modifyPodInPlace = useCallback(
		(pod) => {
			setFormValue(name, pod);
		},
		[name, setFormValue]
	);

	return (
		<ModalWindow
			close={close}
			defaultValue={cloneDeep(podInForm)}
			modalWindowVisible={modalWindowVisible}
			onSubmit={modifyPodInPlace}
			size="medium"
		>
			<EditPodModalContent object={object} />
		</ModalWindow>
	);
};

EditPodModal.propTypes = propTypes;

export default EditPodModal;
