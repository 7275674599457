import { useMemo } from "react";

import { theme } from "@clearpoint/old-theme/Theme";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import PeriodRow from "./PeriodRow";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";

let MeasureGridExcel = () => {
	let translate = useTranslate();
	let { get } = useOldQueryStore();
	let { measureGrid: measureGridList, measureSeriesList } = useDetailPageData();
	let statusList = get({ object: "status", queryString: "?includeNull" });

	measureGridList = useMemo(
		() =>
			measureGridList
				?.filter((x) => !x.hideInGrid)
				.map((period) => {
					let status = statusList?.find((status) => period.statusId === status.statusId);
					return {
						...period,
						statusName: status?.name ?? translate("global.notDefined"),
					};
				}),
		[measureGridList, statusList, translate]
	);

	measureSeriesList = useMemo(
		() => measureSeriesList?.filter((x) => x.active && !x.hiddenSeries),
		[measureSeriesList]
	);

	let tableStyle = useMemo(() => ({ backgroundColor: theme.white, marginBottom: 0 }), []);
	let defaultColumnWidth = useMemo(() => ({ width: "100px" }), []);
	let statusColumnWidth = useMemo(() => ({ width: "150px" }), []);

	return (
		<table
			id="cpsTable"
			className="table table-bordered cps-table"
			data-measure-grid={measureSeriesList?.[0].measureId}
			style={tableStyle}
		>
			<colgroup>
				<col style={defaultColumnWidth} />
				<col style={statusColumnWidth} />
				{measureSeriesList?.map((x) => (
					<col key={x.measureSeriesId} style={defaultColumnWidth} />
				))}
			</colgroup>
			<thead id="cpsHeader">
				<tr className="cps-tr">
					<th rowSpan="2" colSpan="1" className="cps-th" cps-column-id="plainText" data-col-index="0">
						{translate("global.period")}
					</th>
					<th rowSpan="2" colSpan="1" className="cps-th" cps-column-id="name" data-col-index="1">
						{translate("global.status")}
					</th>
					{measureSeriesList?.map((x, i) => (
						<th
							key={x.measureSeriesId}
							className="cps-th"
							rowSpan="2"
							colSpan="1"
							data-col-index={i + 2}
							cps-column-id={`seriesData_${x.measureSeriesId}`}
						>
							{x.name}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{measureGridList?.map((periodData) => (
					<PeriodRow key={periodData.periodId} periodData={periodData} measureSeriesList={measureSeriesList} />
				))}
			</tbody>
		</table>
	);
};

export default MeasureGridExcel;
