import Block from "@clearpoint/old-theme/Block";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import defaultSeries from "./defaultSeries";
import { toast } from "@clearpoint/services/toastService/index";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	gaugeFlag: PropTypes.bool,
	series: PropTypes.shape({
		active: PropTypes.bool,
		measureSeriesId: PropTypes.number,
		name: PropTypes.string,
		seriesType: PropTypes.string,
	}),
};

let DeactivatedChartSeriesListItem = ({ gaugeFlag, series }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { chartSeries: chartSeriesList } = useFormValue();
	let { active, measureSeriesId, name, seriesType } = series;

	let activateSeries = useCallback(() => {
		if (gaugeFlag && chartSeriesList.length > 0) {
			toast.warning(translate("chart.gaugeWarning"));
			return;
		}
		let newSeries = { ...defaultSeries, active, measureSeriesId, name, seriesType };
		setFormValue("chartSeries", [...chartSeriesList, newSeries]);
	}, [active, chartSeriesList, gaugeFlag, measureSeriesId, name, seriesType, setFormValue, translate]);

	return (
		<ListItem
			color="deactivated"
			left={
				<Block ellipsisFlag paddingBottom={theme.tinySpace}>
					{name}
				</Block>
			}
			right={<IconButton onClick={activateSeries} name="activate" />}
		/>
	);
};

DeactivatedChartSeriesListItem.propTypes = propTypes;
export default DeactivatedChartSeriesListItem;
