/* eslint-disable max-depth */
/* eslint-disable complexity */
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";

import findKeyResultUpdates from "./findKeyResultUpdates";

export default function findUpdates({ object, original, updated }) {
	let updates = {};
	let customFieldRegex = /^custom\d*$/;

	for (const updateKey of Object.keys(updated)) {
		if (!["approvalsPending"].includes(updateKey) && !isEqual(updated[updateKey], original?.[updateKey])) {
				let customValueEqualFlag = true;
				let stringEqualFlag = false;
				let customFieldFlag = customFieldRegex.test(updateKey);
				if (customFieldFlag && isObject(original[updateKey]) && isObject(updated[updateKey])) {
					let originalValue = cloneDeep(original[updateKey]);
					let updatedValue = cloneDeep(updated[updateKey]);
					switch (originalValue.customFieldType) {
						case "html":
						case "datahtml":
							if (typeof originalValue.value === "string")
								originalValue.value = originalValue.value.replaceAll(/\s+/g, "");
							if (typeof updatedValue.value === "string")
								updatedValue.value = updatedValue.value.replaceAll(/\s+/g, "");
							break;
						case "boolean":
							if (
								originalValue.value === true ||
								originalValue.value === "1" ||
								originalValue.value === "true" ||
								originalValue.value === "yes"
							)
								originalValue.value = true;
							if (
								!originalValue.value ||
								originalValue.value === "0" ||
								originalValue.value === "false" ||
								originalValue.value === "no"
							)
								originalValue.value = false;
							break;
						case "number":
						case "integer":
						case "percent":
						case "accounting":
						case "statusIcon":
						case "user":
							originalValue.value = Number(originalValue.value);
							updatedValue.value = Number(updatedValue.value);
							break;
						default:
							if (
								updatedValue.value &&
								!Number.isNaN(updatedValue.value) &&
								updatedValue.value.toString() === originalValue.value
							) {
								updatedValue.value = updatedValue.value.toString();
							} else if (originalValue.value === "" && updatedValue.value === undefined) {
								updatedValue.value = "";
							}
							break;
					}
					customValueEqualFlag = originalValue.value === updatedValue.value;
				} else if (
					(updateKey === "analysis" || updateKey === "recommendations") &&
					typeof updated[updateKey] === "string" &&
					typeof original[updateKey] === "string"
				) {
					stringEqualFlag =
						updated[updateKey].replaceAll(/\s+/g, "") === original[updateKey].replaceAll(/\s+/g, "");
				}
				if ((customFieldFlag && !customValueEqualFlag) || (!customFieldFlag && !stringEqualFlag)) {
					if (object === "goals") {
						let originalValue = original[updateKey];
						let updatedValue = updated[updateKey];
						if (updateKey === "keyResults") {
							updates[updateKey] = findKeyResultUpdates({
								original: originalValue,
								updated: updatedValue,
							});
						} else if (!isEqual(originalValue, updatedValue)) {
							updates[updateKey] = updated[updateKey];
						}
					} else {
						updates[updateKey] = updated[updateKey];
					}
				}
			}
	}
	return updates;
}
