import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import ModalWindow from "@clearpoint/old-theme/ModalWindow/ModalWindow";

import { useStateObject } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { buildQueryString, differentiateKpiChartPods } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import EditDetailLayoutModalContent from "./EditDetailLayoutModalContent";
import useCreatePayloadFromSubmitValue from "./useCreatePayloadFromSubmitValue";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	addFlag: PropTypes.bool,
	close: PropTypes.func.isRequired,
	elementName: PropTypes.string,
	layoutId: PropTypes.number,
	modalWindowVisible: PropTypes.bool.isRequired,
	object: PropTypes.string,
	objectId: PropTypes.number,
	periodId: PropTypes.number,
	scorecardId: PropTypes.number,
};

let EditDetailLayoutModal = ({
	close,
	elementName,
	modalWindowVisible,
	object,
	objectId,
	periodId,
	scorecardId,
	...props
}) => {
	let translate = useTranslate();
	let { get, clear } = useOldQueryStore();

	let [{ addFlag, layoutId, openReportOptionsModalFlag }, setEditLayoutModalState] = useStateObject({
		addFlag: props.addFlag,
		layoutId: props.layoutId,
		openReportOptionsModalFlag: false,
	});
	let setLayoutId = useCallback(
		(layoutId) => {
			setEditLayoutModalState({ layoutId });
		},
		[setEditLayoutModalState]
	);

	let {
		layoutId: sessionLayoutId,
		object: sessionObject,
		objectId: sessionObjectId,
		periodId: sessionPeriodId,
		scorecardId: sessionScorecardId,
	} = useOldSession().session;
	layoutId = layoutId ?? sessionLayoutId;
	object = object ?? sessionObject;
	objectId = objectId ?? sessionObjectId;
	periodId = periodId ?? sessionPeriodId;
	scorecardId = scorecardId ?? sessionScorecardId;

	let dashboardLayoutFlag = object === "scorecard";

	let { createDashboardLayoutPayload, createPayloadFromSubmitValue } = useCreatePayloadFromSubmitValue({
		dashboardLayoutFlag,
		scorecardId,
	});

	let queryString = dashboardLayoutFlag ? undefined : buildQueryString({ object, objectId });
	let queryStringOnSubmit = dashboardLayoutFlag
		? (addFlag
			? buildQueryString({ scorecardId: sessionScorecardId })
			: null)
		: buildQueryString({ object, objectId });

	let onSubmit = useCallback(
		({ saveOption }) => {
			if (dashboardLayoutFlag) {
				clear({
					object: "dashboardLayout",
					scorecardId: sessionScorecardId,
				});
				clear({
					object: "scorecard",
					objectId: sessionScorecardId,
				});
				clear({
					object: "dashboardLayout",
					objectId: layoutId,
					queryString: queryString || buildQueryString({ periodId }),
				});
			} else if (saveOption === 0) {
				clear({ object: "detailLayout", queryString });
				clear({ object, objectId });
			} else {
				clear({ object: "detailLayout" });
				clear({ object });
			}
			toast.success(translate("toaster.messages.global.layoutSaved"));
		},
		[clear, dashboardLayoutFlag, layoutId, periodId, queryString, sessionScorecardId, translate]
	);

	let layout = get({
		object: dashboardLayoutFlag ? "dashboardLayout" : "detailLayout",
		objectId: dashboardLayoutFlag ? layoutId : undefined,
		queryString,
	});
	let defaultValue = useMemo(() => {
		if (!addFlag) {
			return differentiateKpiChartPods(layout);
		}
		let blankArea = {
			pods: [],
			page: 0,
		};
		return {
			accessType: "default",
			layout: Array.from({length: 4}).fill(blankArea).map((x, i) => ({ area: i, class: "area" + i, ...x })),
			name: "",
			scorecardId: sessionScorecardId,
		};
	}, [addFlag, layout, sessionScorecardId]);

	return (
		<ModalWindow
			addFlag={addFlag}
			beforeSubmit={createPayloadFromSubmitValue}
			close={close}
			color="lazur"
			createDashboardLayoutPayload={createDashboardLayoutPayload}
			dashboardLayoutFlag={dashboardLayoutFlag}
			defaultValue={defaultValue}
			elementName={elementName}
			elementScorecardId={scorecardId}
			modalWindowVisible={modalWindowVisible}
			object={dashboardLayoutFlag ? "dashboardLayout" : "detailLayout"}
			objectId={dashboardLayoutFlag ? layoutId : undefined}
			onSubmit={onSubmit}
			openReportOptionsModalFlag={openReportOptionsModalFlag}
			queryString={queryString}
			queryStringOnSubmit={queryStringOnSubmit}
			setEditLayoutModalState={setEditLayoutModalState}
			setObjectId={setLayoutId}
			showDiscardModalOnFormDirtyFlag
			submitUnchangedFlag
		>
			<EditDetailLayoutModalContent elementName={elementName} dashboardLayoutFlag={dashboardLayoutFlag} />
		</ModalWindow>
	);
};

EditDetailLayoutModal.propTypes = propTypes;
export default EditDetailLayoutModal;
