import Block from "@clearpoint/old-theme/Block";
import Icon from "@clearpoint/old-theme/Icon/Icon";
import ListItem from "@clearpoint/old-theme/ListItem/ListItem";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import getIconName from "./getIconName";
import { theme } from "@clearpoint/old-theme/Theme";
import { DISCUSSION_POD, emptyObject, formatNameForTestId } from "@clearpoint/utils";
import { useCheckFeature } from "@clearpoint/hooks";

let chartEarlyReturnPatternList = ["chart", "kpi"];

let exactMatchList = [
	"childInitiatives",
	"childMeasures",
	"goals",
	"initiativeMeasures",
	"initiativeMilestones",
	"measureInitiatives",
];

let propTypes = {
	attributes: PropTypes.object,
	backgroundColor: PropTypes.string,
	className: PropTypes.string,
	draggableFlag: PropTypes.bool,
	listeners: PropTypes.object,
	pod: PropTypes.shape({
		fieldId: PropTypes.string.isRequired,
		name: PropTypes.string,
		title: PropTypes.string,
	}),
	right: PropTypes.node,
	setActivatorNodeRef: PropTypes.func,
};

let PodListItem = ({
	attributes,
	backgroundColor,
	className,
	draggableFlag,
	listeners,
	pod,
	right,
	setActivatorNodeRef,
}) => {
	let translate = useTranslate();
	let pageObject = useFormValue("object");
	let checkFeature = useCheckFeature();

	let { fieldId, name, title } = pod || emptyObject;
	let discussionPodFlag = fieldId === "discussion";
	let discussionPodEnabledFlag = checkFeature(DISCUSSION_POD);
	let displayText = translate(name || title, { objectType: pageObject });

	return (
		!(discussionPodFlag && !discussionPodEnabledFlag) && (
			<ListItem
				data-testid={`${formatNameForTestId(displayText)}-list-item`}
				draggableFlag={draggableFlag}
				dragHandleAttributes={attributes}
				dragHandleListeners={listeners}
				setActivatorNodeRef={setActivatorNodeRef}
				backgroundColor={backgroundColor}
				boxShadow="2px 2px 8px -4px rgb(0 0 0 / 50%)"
				className={className}
				leftPseudoPadding="0px"
				buttonCount={1}
				left={
					<Block ellipsisFlag display="flex" alignItems="center" marginBottom="0">
						<Icon
							name={
								getIconName({
									chartEarlyReturnPatternList,
									chartEarlyReturnValue: "pieChart",
									exactMatchList,
									fieldId,
								}) ||
								pageObject ||
								"scorecard"
							}
							size="large"
							marginRight={theme.space}
						/>
						<Block ellipsisFlag>{displayText}</Block>
					</Block>
				}
				right={right}
			/>
		)
	);
};

PodListItem.propTypes = propTypes;

export default PodListItem;
