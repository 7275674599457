import Badge from "@clearpoint/old-theme/Badge/Badge";
import Block from "@clearpoint/old-theme/Block";
import FormDragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import IconButton from "@clearpoint/old-theme/Button/Button.Icon";
import ModalConfirmOnClick from "@clearpoint/old-theme/Modal/Modal.Confirm.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { theme } from "@clearpoint/old-theme/Theme";

let propTypes = {
	series: PropTypes.shape({
		color: PropTypes.string,
		isSecondAxis: PropTypes.bool,
		label: PropTypes.string,
		periodOffset: PropTypes.number,
		name: PropTypes.string,
		regressionModel: PropTypes.string,
		seriesType: PropTypes.oneOf(["offset", "standard", "trendline", "aggregate"]),
		visible: PropTypes.bool,
	}),
};

let TransposedSeriesListItem = ({ series }) => {
	let translate = useTranslate();
	let { setFormValue } = useFormContext();
	let { chartSeries: chartSeriesList } = useFormValue();
	let { label, name, periodOffset, regressionModel, seriesType, visible } = series;
	let trendlineFlag = seriesType === "trendline" && regressionModel !== "";
	let offsetFlag = seriesType === "offset" && periodOffset > 0;
	let labelText = label || name;

	let deactivateSeries = useCallback(() => {
		let index = chartSeriesList.findIndex(
			(chartSeries) =>
				series.measureSeriesId === chartSeries.measureSeriesId && series.sortOrder === chartSeries.sortOrder
		);
		if (index !== -1) {
			setFormValue("chartSeries", [...chartSeriesList.slice(0, index), ...chartSeriesList.slice(index + 1)]);
		}
	}, [chartSeriesList, series, setFormValue]);

	return (
		<FormDragAndDropListItem
			buttonCount={2}
			color="desaturatedBold"
			visible={visible}
			left={
				<Block>
					<Block>{labelText}</Block>
					<Block fontSize="85%" fontWeight={theme.normal}>
						{name}
					</Block>
				</Block>
			}
			right={
				<Block>
					{trendlineFlag && (
						<StyleWrapper marginRight={theme.smallSpace}>
							<Badge color="success">{translate("edit.chart.trendline")}</Badge>
						</StyleWrapper>
					)}
					{offsetFlag && (
						<StyleWrapper marginRight={theme.smallSpace}>
							<Badge color="warning">{translate("edit.chart.offset")}</Badge>
						</StyleWrapper>
					)}
					<ModalConfirmOnClick
						confirmButtonColor="danger"
						confirmButtonText={translate("global.remove")}
						title={translate("edit.chartSeries.removeChartSeries")}
						body={translate("edit.chartSeries.removeChartSeriesConfirm")}
						onConfirm={deactivateSeries}
					>
						<IconButton color="danger" name="remove" size="tiny" />
					</ModalConfirmOnClick>
				</Block>
			}
		/>
	);
};

TransposedSeriesListItem.propTypes = propTypes;

export default TransposedSeriesListItem;
