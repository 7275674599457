import Button from "../Button/Button";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import { useCallback } from "react";
import { useFormContext } from "../Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import { emptyObject, joinNameList, toBoolean } from "@clearpoint/utils";

let propTypes = {
	close: PropTypes.func.isRequired,
	field: PropTypes.string,
	modalVisible: PropTypes.bool,
	parentName: PropTypes.string.isRequired,
};

let CopyForwardModal = ({ close, modalVisible, field, parentName }) => {
	let translate = useTranslate();
	let formValue = useFormValue(parentName);
	let { object, objectId, previousPeriodId } = formValue || emptyObject;
	let { getPromise } = useOldQueryStore();
	let { setFormValue } = useFormContext();
	let copyForward = useCallback(async () => {
		if (!object || !objectId) throw new Error("Missing object/objectId for copy forward");
		let [defaultFieldData, customFieldData] = await Promise.all([
			previousPeriodId && objectId && getPromise({ object, objectId, periodId: previousPeriodId }),
			previousPeriodId &&
				objectId &&
				getPromise({
					object: "customField",
					parent: object,
					parentId: objectId,
					periodId: previousPeriodId,
				}),
		]);
		let updateCustomFieldData = {};
		if (customFieldData) {
			for (let [key, value] of Object.entries(customFieldData)) {
				if (value.updateMode) {
					updateCustomFieldData[key] = value;
				}
			}
		}
		let previousPeriodUpdateFieldData = { ...defaultFieldData, ...updateCustomFieldData };
		delete previousPeriodUpdateFieldData.periodId;
		if (field) {
			setFormValue(joinNameList(parentName, field), previousPeriodUpdateFieldData[field]);
		} else {
			for (let [key, value] of Object.entries(formValue)) {
				let booleanCustomFieldFlag = value?.customFieldType === "boolean";
				let currentPeriodValue = value && typeof value === "object" ? value.value : value;
				let previousPeriodField = previousPeriodUpdateFieldData?.[key];

				let previousPeriodUpdateValue = booleanCustomFieldFlag
					? { ...previousPeriodField, value: toBoolean(previousPeriodField?.value) }
					: previousPeriodField;

				if (!currentPeriodValue) {
					setFormValue(joinNameList(parentName, key), previousPeriodUpdateValue);
				}
			}
		}
		close();
	}, [close, field, formValue, getPromise, object, objectId, parentName, previousPeriodId, setFormValue]);
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<ModalHeader>
				<ModalTitle>{translate("edit.copyForward")}</ModalTitle>
			</ModalHeader>
			<ModalBody>{translate("edit.copyForwardAlert")}</ModalBody>
			<ModalFooter>
				<Button onClick={close} type="button" color="default">
					{translate("global.cancel")}
				</Button>
				<Button onClick={copyForward} color="success" data-testid="copyForwardAll">
					{translate("edit.copyForward")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

CopyForwardModal.propTypes = propTypes;

export default CopyForwardModal;
