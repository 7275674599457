import AddButton from "@clearpoint/old-theme/Button/Button.Add";
import ChartSeriesListItem from "./ChartSeriesListItem";
import DeactivatedChartSeriesListItem from "../shared/DeactivatedChartSeriesListItem";
import EditChartSeriesModal from "./EditChartSeriesModal";
import FilterInput from "@clearpoint/old-theme/Filter/Filter.Input";
import FilterWrapper from "../shared/FilterWrapper";
import FormDragAndDropList from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList";
import NoResults from "@clearpoint/old-theme/NoResults";
import { useCallback, useMemo } from "react";
import { useFilter } from "@clearpoint/providers/FilterProvider";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useSeriesData from "../shared/useSeriesData";
import { useTranslate } from "@clearpoint/translate";
import PropTypes from "prop-types";
import mapVisibleToList from "../shared/mapVisibleToList";
import { toast } from "@clearpoint/services/toastService/index";

let listContentPropTypes = {
	activatedSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			dragAndDropListKey: PropTypes.number.isRequired,
			label: PropTypes.string,
			name: PropTypes.string,
			sortOrder: PropTypes.number,
		})
	),
	deactivatedSeriesList: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool,
			name: PropTypes.string,
			object: PropTypes.string,
		})
	),
	gaugeFlag: PropTypes.bool,
};

let ChartSeriesListContent = ({ activatedSeriesList, deactivatedSeriesList, gaugeFlag }) => {
	let translate = useTranslate();
	let { chartSeries: chartSeriesList } = useFormValue();
	let { data: filteredSeriesList, setData: setSeriesList } = useFilter();
	let { setFormValue } = useFormContext();
	activatedSeriesList = useMemo(
		() =>
			mapVisibleToList(activatedSeriesList, filteredSeriesList)?.map((series, index) => ({
				...series,
				sortOrder: index,
			})),
		[activatedSeriesList, filteredSeriesList]
	);

	deactivatedSeriesList = useMemo(
		() =>
			mapVisibleToList(deactivatedSeriesList, filteredSeriesList)?.map((series, index) => ({
				...series,
				sortOrder: index,
			})),
		[deactivatedSeriesList, filteredSeriesList]
	);

	let setList = useCallback(
		(list) => {
			setSeriesList([...list, ...deactivatedSeriesList]);
		},
		[deactivatedSeriesList, setSeriesList]
	);

	let removeEmptySeries = useCallback(() => {
		let correctedSeries = chartSeriesList.slice(0, chartSeriesList.length - 1);
		if (correctedSeries.length < chartSeriesList.length) {
			setFormValue("chartSeries", correctedSeries);
		}
	}, [chartSeriesList, setFormValue]);

	let showGaugeWarning = useCallback(() => {
		if (gaugeFlag && chartSeriesList.length > 0) {
			toast.warning(translate("chart.gaugeWarning"));
		}
	}, [chartSeriesList.length, gaugeFlag, translate]);

	return (
		<>
			<FilterInput
				name="seriesFilter"
				filterBy="name"
				placeholder={translate("adminProjectEval.filterSeries") + "..."}
				right={
					<AddButton
						data-testid="addSeriesButton"
						onCancel={removeEmptySeries}
						onClick={showGaugeWarning}
						Modal={gaugeFlag ? undefined : EditChartSeriesModal}
					/>
				}
			/>
			<NoResults />
			{!activatedSeriesList.every((series) => !series.visible) && (
				<FormDragAndDropList chartSeriesListFlag name="chartSeries" list={activatedSeriesList} setList={setList}>
					{activatedSeriesList
						?.filter((x) => x.visible)
						?.map((series, index) => (
							<ChartSeriesListItem
								key={series.dragAndDropListKey}
								list={activatedSeriesList}
								index={index}
								series={series}
								setList={setList}
							/>
						))}
				</FormDragAndDropList>
			)}
			{deactivatedSeriesList
				.filter((x) => x.visible)
				.map((series, index) => (
					<DeactivatedChartSeriesListItem key={index} series={series} gaugeFlag={gaugeFlag} />
				))}
		</>
	);
};

let propTypes = {
	gaugeFlag: PropTypes.bool,
};

let ChartSeriesList = ({ gaugeFlag }) => {
	let { combinedSeriesList, activatedSeriesList, deactivatedSeriesList } = useSeriesData();
	if (gaugeFlag && activatedSeriesList.length > 1) {
		let extra = activatedSeriesList.slice(1, activatedSeriesList.length - 1);
		deactivatedSeriesList = [...deactivatedSeriesList, ...extra];
		activatedSeriesList = activatedSeriesList.slice(0, 1);
	}
	return (
		<FilterWrapper data={combinedSeriesList}>
			<ChartSeriesListContent
				activatedSeriesList={activatedSeriesList}
				deactivatedSeriesList={deactivatedSeriesList}
				gaugeFlag={gaugeFlag}
			/>
		</FilterWrapper>
	);
};

ChartSeriesListContent.propTypes = listContentPropTypes;
ChartSeriesListContent.defaultProps = {
	activatedSeriesList: [],
	deactivatedSeriesList: [],
};
ChartSeriesList.propTypes = propTypes;
export default ChartSeriesList;
