import { emptyObject } from "@clearpoint/utils";

let getChartType = (chartData) => {
	if (!chartData) return;
	let { chartFormat: { chart: { polar: polarFlag, type } } = emptyObject, transposed: transposedFlag } = chartData;
	if (type === "pie") return "pie";
	if (["gauge", "solidgauge"].includes(type)) return "gauge";
	if (polarFlag) return "polar";
	if (transposedFlag) return "transposed";
	return "default";
};
export default getChartType;
