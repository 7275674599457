import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { useTranslate } from "@clearpoint/translate";
import { capitalizeFirstLetter } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import Button from "../Button/Button";
import FormButton from "../Form/Button/Button";
import FormFieldName from "../Form/Field/Name";
import Form from "../Form/Form";
import Modal from "./Modal";
import ModalBody from "./Modal.Body";
import ModalFooter from "./Modal.Footer";
import ModalHeader from "./Modal.Header";
import ModalTitle from "./Modal.Title";
import useToastApproval from "@clearpoint/hooks-dir/useToastApproval";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	Options: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	beforeSubmit: PropTypes.func,
	close: PropTypes.func.isRequired,
	defaultValue: PropTypes.object,
	duplicateRouteFlag: PropTypes.bool,
	modalVisible: PropTypes.bool,
	nameLabel: PropTypes.string,
	object: PropTypes.string,
	objectId: PropTypes.number,
	onSubmit: PropTypes.func,
	preventDefaultOnSubmitFlag: PropTypes.bool,
	scorecardId: PropTypes.number,
	submitUnchangedFlag: PropTypes.bool,
	title: PropTypes.string,
	toastMessage: PropTypes.string,
};

let CopyModal = ({
	Options,
	beforeSubmit,
	close,
	defaultValue,
	duplicateRouteFlag,
	modalVisible,
	nameLabel,
	object,
	objectId,
	onSubmit,
	parent,
	parentId,
	preventDefaultOnSubmitFlag,
	scorecardId,
	submitUnchangedFlag,
	title,
	toastMessage,
}) => {
	let translate = useTranslate();
	let [savingFlag, setSavingFlag] = useState(false);
	let { session } = useOldSession();
	let location = useLocation();
	let history = useHistory();
	let toastApproval = useToastApproval();

	// if null failed to fetch submitKeys
	let submitKeys = useMemo(() => {
		if (!Options || !Options?.props?.children) return null;
		let optionFieldList = Options?.props?.children?.map((x) => x?.props?.name) || [];
		if (optionFieldList.length === 0) return null;
		return ["name", ...optionFieldList];
	}, [Options]);

	let filterKeysBeforeSubmit = useCallback(
		(submitValue, contextValue) => {
			let submitValueHandled = beforeSubmit ? beforeSubmit(submitValue, contextValue) : submitValue;
			if (!submitKeys) return submitValueHandled;
			return submitKeys.reduce((acc, key) => {
				if (submitValueHandled[key] !== undefined) acc[key] = submitValueHandled[key];
				return acc;
			}, {});
		},
		[beforeSubmit, submitKeys]
	);
	let handleSubmit = useCallback(
		(...args) => {
			const { scorecardId } = session;
			const formValues = args[0];
			if (onSubmit) onSubmit(...args);

			if (args[1]?.action === "copyQueued") {
				toastApproval({ response: args[1] });
			} else if (toastMessage) {
				toast.success(toastMessage);
			}

			close();
			if (formValues?.scorecardId !== scorecardId) {
				const redirect =
					location.pathname +
					location.search.replace(`scorecardId=${scorecardId}`, `scorecardId=${formValues.scorecardId}`);
				history.push(redirect);
			}
		},
		[close, onSubmit, toastMessage]
	);
	return (
		<Modal modalVisible={modalVisible} close={close} size="medium">
			<Form
				parent={parent}
				parentId={parentId}
				object={object}
				objectId={objectId}
				scorecardId={scorecardId}
				defaultValue={defaultValue}
				copyFlag
				beforeSubmit={filterKeysBeforeSubmit}
				onSubmit={handleSubmit}
				submitUnchangedFlag={submitUnchangedFlag}
				duplicateRouteFlag={duplicateRouteFlag}
				preventDefaultOnSubmitFlag={preventDefaultOnSubmitFlag}
			>
				<ModalHeader>
					<ModalTitle>
						{title || translate("edit.duplicateElement", { InsertText: capitalizeFirstLetter(object) })}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormFieldName
						name="name"
						label={
							nameLabel ||
							(object
								? translate("edit.newElementName", {
										element: capitalizeFirstLetter(object),
								  })
								: translate("global.name"))
						}
						object={object}
						placeholder={translate("global.name")}
					/>
					{Options && <Options />}
				</ModalBody>
				<ModalFooter>
					{!savingFlag && (
						<Button data-testid="cancel-button" onClick={close} type="button" color="default">
							{translate("global.cancel")}
						</Button>
					)}
					<FormButton
						data-testid="duplicate-button"
						onClick={() => setSavingFlag(true)}
						color="primary"
						type="submit"
					>
						{translate("global.duplicate")}
					</FormButton>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

CopyModal.propTypes = propTypes;

export default CopyModal;
