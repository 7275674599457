import { useCallback } from "react";
import { useTranslate } from "@clearpoint/translate";
import cloneDeep from "lodash/cloneDeep";
import configureAccessibility from "./configureAccessibility";
import configureChartHeight from "./configureChartHeight";
import configureColors from "./configureColors";
import configureExporting from "./configureExporting";
import configureLegend from "./configureLegend";
import configurePane from "./configurePane";
import configurePlotOptions from "./configurePlotOptions";
import configureSeries from "./configureSeries";
import configureXAxis from "./configureXAxis";
import configureYAxis from "./configureYAxis";
import isArray from "lodash/isArray";
import { parseChartStyle } from "./utils";

export default function useFormatChart() {
	let defaultTranslate = useTranslate();
	return useCallback(
		({ chartData, measureGridList, translate }) => {
			if (!chartData) return;
			if (isArray(chartData) && chartData.length === 0) return;

			if (!translate) translate = defaultTranslate;
			let chartDataCopy = cloneDeep(chartData);
			let { chartFormat } = chartDataCopy;
			let series = configureSeries(chartDataCopy, measureGridList);
			return !chartFormat
				? undefined
				: {
						...chartFormat,
						accessibility: configureAccessibility({
							chartData: chartDataCopy,
							series,
							translate,
						}),
						chart: {
							...chartFormat.chart,
							height: configureChartHeight(chartDataCopy),
							style: parseChartStyle(chartDataCopy),
						},
						colors: configureColors(chartDataCopy),
						exporting: configureExporting(chartDataCopy),
						legend: configureLegend(chartDataCopy),
						pane: configurePane(chartDataCopy),
						plotOptions: configurePlotOptions(chartDataCopy),
						series,
						xAxis: configureXAxis(chartDataCopy, measureGridList),
						yAxis: configureYAxis(chartDataCopy, measureGridList),
				  };
		},
		[defaultTranslate]
	);
}
