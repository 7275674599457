import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAlignLeft,
	faAngleDown,
	faAngleUp,
	faArrowDown,
	faArrowDownUpAcrossLine, 
	faArrowLeft,
	faArrowRight,
	faArrowsAlt,
	faArrowUp,
	faBan,
	faBars,
	faBell,
	faBellSlash,
	faBolt,
	faBook,
	faBookmark,
	faBriefcase,
	faCalculator,
	faCalendar,
	faCalendarCheck,
	faCaretDown,
	faChartColumn,
	faChartLine,
	faChartPie,
	faCheck,
	faCheckSquare,
	faChevronDown,
	faCircle,
	faCircleCheck,
	faCircleHalfStroke,
	faCircleNotch,
	faCirclePlay,
	faCloudUploadAlt,
	faCode,
	faCog,
	faCogs,
	faComment,
	faComments,
	faCube,
	faDownload,
	faEdit,
	faEllipsisV,
	faEnvelope,
	faExchangeAlt,
	faExclamationTriangle,
	faExternalLinkAlt,
	faEyeDropper,
	faFile,
	faFilter,
	faFlag,
	faHatCowboy,
	faHistory,
	faImage,
	faIndent,
	faIndustry,
	faKey,
	faLevelDownAlt,
	faLevelUpAlt,
	faLink,
	faListUl,
	faLock,
	faMap,
	faMinus,
	faMinusCircle,
	faPaintBrush,
	faPaperclip,
	faPaperPlane,
	faPencilAlt,
	faPenSquare,
	faPlay,
	faPlus,
	faPlusSquare,
	faPrint,
	faRecycle,
	faRedo,
	faRedoAlt,
	faRocket,
	faRss,
	faSave,
	faSearch,
	faSearchMinus,
	faSearchPlus,
	faSignOutAlt,
	faSitemap,
	faSort,
	faSortAlphaDown,
	faSortDown,
	faSortUp,
	faSpinner,
	faSquare,
	faStar,
	faSync,
	faSyncAlt,
	faTable,
	faTags,
	faTh,
	faThumbtack,
	faTimes,
	faTrashAlt,
	faTv,
	faUndo,
	faUniversity,
	faUnlink,
	faUnlock,
	faUpload,
	faUser,
	faUserPlus,
	faUsers,
	faVolumeOff,
	faVolumeUp} from "@fortawesome/free-solid-svg-icons";
import { faCalculatorSimple } from "@fortawesome/pro-solid-svg-icons";

library.add(
	faAlignLeft,
	faAngleDown,
	faAngleUp,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
	faArrowsAlt,
	faArrowsAlt,
	faBan,
	faBars,
	faBell,
	faBellSlash,
	faBolt,
	faBook,
	faBookmark,
	faBriefcase,
	faCalculator,
	faCalculatorSimple,
	faCalendar,
	faCalendarCheck,
	faCaretDown,
	faChartColumn,
	faChartLine,
	faChartPie,
	faCheck,
	faCheckSquare,
	faChevronDown,
	faCircle,
	faCircleCheck,
	faCircleHalfStroke,
	faCircleNotch,
	faCirclePlay,
	faCloudUploadAlt,
	faCode,
	faCog,
	faCogs,
	faComment,
	faComments,
	faCube,
	faDownload,
	faEdit,
	faEllipsisV,
	faEnvelope,
	faExchangeAlt,
	faExclamationTriangle,
	faExternalLinkAlt,
	faEyeDropper,
	faFile,
	faFilter,
	faFlag,
	faHatCowboy,
	faHistory,
	faImage,
	faIndent,
	faIndustry,
	faKey,
	faLevelDownAlt,
	faLevelUpAlt,
	faLink,
	faListUl,
	faLock,
	faMap,
	faMinus,
	faMinusCircle,
	faPaintBrush,
	faPaperPlane,
	faPaperclip,
	faPenSquare,
	faPencilAlt,
	faPlay,
	faPlus,
	faPlusSquare,
	faPrint,
	faRecycle,
	faRedo,
	faRedoAlt,
	faRocket,
	faRss,
	faSave,
	faSearch,
	faSearchMinus,
	faSearchPlus,
	faSignOutAlt,
	faSitemap,
	faSort,
	faSortAlphaDown,
	faSortDown,
	faSortUp,
	faSpinner,
	faSquare,
	faStar,
	faSync,
	faSyncAlt,
	faTable,
	faTags,
	faTh,
	faThumbtack,
	faTimes,
	faTrashAlt,
	faTv,
	faUndo,
	faUniversity,
	faUnlink,
	faUnlock,
	faUpload,
	faUser,
	faUserPlus,
	faUsers,
	faVolumeOff,
	faVolumeUp,
	faArrowDownUpAcrossLine 
);
