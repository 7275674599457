import Column from "@clearpoint/old-theme/Column";
import DualColor from "./DualColor";
import DualSeries from "./DualSeries";
import FormInput from "@clearpoint/old-theme/Form/Input/Input";
import Row from "@clearpoint/old-theme/Row";
import SelectChart from "@clearpoint/old-theme/Form/Select/Select.Chart";
import SingleColor from "./SingleColor";
import SingleSeries from "./SingleSeries";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { BAR, CHART, DONUT, LINE, METRIC } from "../chartTypes";

let colorComponentLookup = {
	[BAR]: SingleColor,
	[DONUT]: DualColor,
	[LINE]: DualColor,
};

let seriesComponentLookup = {
	[BAR]: SingleSeries,
	[DONUT]: DualSeries,
	[LINE]: SingleSeries,
	[METRIC]: SingleSeries,
};

let ChartConfiguration = () => {
	let translate = useTranslate();
	let { chartType, measureId } = useFormValue("chart");

	let chartSelectFlag = measureId && chartType === CHART;

	let SeriesComponent = measureId && seriesComponentLookup[chartType];
	let ColorComponent = measureId && colorComponentLookup[chartType];

	return (
		<Row>
			{SeriesComponent && <SeriesComponent />}
			{chartSelectFlag && (
				<Column tiny={12}>
					<SelectChart
						name="chart.chartId"
						label={translate("chart")}
						object="measure"
						objectId={measureId}
						required
					/>
				</Column>
			)}
			<Column tiny={12}>
				<FormInput name="chart.title" label={translate("manage.chart.chartTitle")} required />
			</Column>
			{ColorComponent && <ColorComponent />}
		</Row>
	);
};

export default ChartConfiguration;
