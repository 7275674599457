import { useEffect, useRef } from "react";
import isArray from "lodash/isArray";
import PropTypes from "prop-types";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { useFormatDate } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { getCurrentDate } from "@clearpoint/utils";

let propTypes = {
	link: PropTypes.shape({
		completed: PropTypes.bool,
		completedDate: PropTypes.any,
		endDate: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		startDate: PropTypes.string,
	}),
};

let Date = ({ link }) => {
	let translate = useTranslate();
	let formatDate = useFormatDate();
	let { completedDate, endDate, object, objectId, startDate } = link;
	let { setFormValue, formDefaultValue, formValue } = useFormContext();
	let formLink = formValue?.links?.find((link) => link?.objectId === objectId);
	let defaultLink = formDefaultValue?.links?.find((link) => link?.objectId === objectId);
	let completedDateRef = useRef();

	useEffect(() => {
		if (defaultLink?.completed === false && formLink?.completed && object !== "objective" && object !== "measure") {
			completedDateRef.current = getCurrentDate();
			let index = isArray(formValue?.links)
				? formValue?.links?.findIndex?.((link) => link?.objectId === objectId)
				: -1;
			if (index !== -1) {
				setFormValue(`links.${index}.completedDate`, formatDate(completedDateRef.current, "apiDate"));
			}
		} else {
			completedDateRef.current = defaultLink?.completedDate;
		}
	}, [
		defaultLink?.completed,
		completedDate,
		formLink?.completed,
		defaultLink?.completedDate,
		formDefaultValue.links,
		setFormValue,
		objectId,
		object,
		formValue?.links,
		formatDate,
	]);

	let visible = ["milestone", "initiative", "actionItem", "risk"].includes(object);

	return visible ? (
		<small>
			{formLink?.completed
				? `${translate("components.completed")} ${formatDate(completedDateRef.current, "short")}`
				: `${formatDate(startDate, "short")} - ${formatDate(endDate, "short")}`}
		</small>
	) : null;
};

Date.propTypes = propTypes;
export default Date;

// Responsiveness we're probably not going to wind up using:

// let showFlag = props.showFlag,
// let smallScreenFlag = props.smallScreenFlag
// let visible = ["milestone", "initiative", "actionItem", "risk"].includes(object) && (showFlag || !smallScreenFlag);