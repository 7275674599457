import { useCallback } from "react";
import PropTypes from "prop-types";

import Button from "@clearpoint/old-theme/Button/Button";
import Column from "@clearpoint/old-theme/Column";
import FormCheckArrayItem from "@clearpoint/old-theme/Form/CheckArray/CheckArray.Item";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import ModalOnClick from "@clearpoint/old-theme/Modal/Modal.OnClick";
import Row from "@clearpoint/old-theme/Row";
import { theme } from "@clearpoint/old-theme/Theme";

import { useTranslate } from "@clearpoint/translate";

import SelectElementsModal from "./SelectElementsModal";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
  scorecard: PropTypes.shape({
    name: PropTypes.string,
    scorecardId: PropTypes.number,
  }),
  object: PropTypes.string
};

let ScorecardListItem = ({ scorecard, object }) => {
  let translate = useTranslate();
  let { name, scorecardId } = scorecard;
  let { session } = useOldSession();
  let { elementList, layoutId, scorecardId: formScorecardId, scorecardIdList } = useFormValue();
  formScorecardId = formScorecardId || session.scorecardId;
  let isChecked = useCallback(
    (scorecardId) => scorecardIdList?.split(",").includes(scorecardId.toString()),
    [scorecardIdList]
  );
  let elementCount = elementList?.find((x) => x.scorecardId === scorecardId)?.elements?.length || 0;
  return (
    <Row>
      <Column large={6}>
        <FormCheckArrayItem
          defaultCheckedFlag={!layoutId && scorecardId === formScorecardId}
          labelWeight="bold"
          marginBottom={isChecked(scorecardId) ? "0px" : theme.smallSpace}
          value={scorecardId}
        >
          {name}
        </FormCheckArrayItem>
      </Column>
      {isChecked(scorecardId) && (
        <Column large={6}>
          <ModalOnClick Modal={SelectElementsModal} scorecardId={scorecardId} object={object}>
            <Button
              color="primary"
              blockFlag
              marginBottom={theme.smallSpace}
              outlineFlag={!elementCount}
              size="small"
            >
              {translate(
                elementCount ? "tabGridElements.specificElementsSelected" : "tabGridElements.specificElements"
              )}
            </Button>
          </ModalOnClick>
        </Column>
      )}
    </Row>
  );
};

ScorecardListItem.propTypes = propTypes;

export default ScorecardListItem;
