import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback, useContext, useEffect, useState, createContext } from "react";
import useCheckPodEditable from "./useCheckPodEditable";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import useGridPodFieldIdList from "./useGridPodFieldIdList";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import usePodWidthDisplayClass from "./usePodWidthDisplayClass";
import PropTypes from "prop-types";
import { emptyArray, emptyObject } from "@clearpoint/utils";

let PodContext = createContext();

let propTypes = {
	areaClass: PropTypes.string,
	children: PropTypes.node,
	fieldId: PropTypes.string,
	hideBorderFlag: PropTypes.bool,
	podWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // [new UI, old UI]
	readOnlyFlag: PropTypes.bool,
	updateModeFlag: PropTypes.bool,
};

let PodProvider = ({
	areaClass,
	children,
	fieldId,
	hideBorderFlag,
	podWidth,
	readOnlyFlag,
	updateModeFlag,
	...props
}) => {
	let [editPodFlag, setEditPodFlag] = useState(false);
	let { editElementFlag, lockedPodList, setLocalState } = useLocalState();
	let formValue = useFormValue();
	let { setFormValue } = useFormContext();
	let lockedFlag = lockedPodList && lockedPodList.length > 0 && lockedPodList.includes(fieldId);

	let nameInForm = updateModeFlag ? `updates[${fieldId}]` : fieldId;
	let customFieldFlag = fieldId.slice(0, 6) === "custom";

	let valueInForm = useFormValue(nameInForm) || emptyObject;
	let { customFieldType, value: customFieldValue } = customFieldFlag ? valueInForm : emptyObject;
	let gridReportFlag = customFieldType === "grid";
	let chartCustomFieldFlag = customFieldType === "chart";
	let chartPodFlag = fieldId.substring(0, 5) === "chart" && fieldId !== "chartData";
	let chartId = chartPodFlag
		? +fieldId.replace("chart", "")
		: chartCustomFieldFlag
		? +customFieldValue.split(",")[2]
		: undefined;

	let checkPodEditable = useCheckPodEditable({ chartCustomFieldFlag, fieldId, gridReportFlag });

	let gridPodFieldIdList = useGridPodFieldIdList(formValue);

	let lockGridPods = useCallback(() => {
		setLocalState({ lockedPodList: gridPodFieldIdList });
	}, [gridPodFieldIdList, setLocalState]);

	let openEditMode = useCallback(() => {
		if (
			checkPodEditable({
				chartId,
				fieldId,
				readOnlyFlag,
				updateModeFlag,
			})
		) {
			if (fieldId !== "discussion") {
				setLocalState({ editElementFlag: true });
			}

			setEditPodFlag(true);
			if (!gridReportFlag) {
				lockGridPods();
			}
		}
	}, [chartId, checkPodEditable, fieldId, gridReportFlag, lockGridPods, readOnlyFlag, setLocalState, updateModeFlag]);

	let closeEditMode = useCallback(() => {
		setLocalState({ editElementFlag: false });
		setEditPodFlag(false);
	}, [setLocalState]);

	let closeGridReportCells = useCallback(() => {
		Object.keys(formValue).forEach((key) => {
			if (key.startsWith("_editCell")) {
				setFormValue(key, false);
			}
		});
	}, [formValue, setFormValue]);

	// Automatically close pod if detail page's overall edit mode switches to false
	useEffect(() => {
		if (!editElementFlag) {
			setEditPodFlag(false);
			setLocalState({ lockedPodList: emptyArray });
			closeGridReportCells();
		}
	}, [closeGridReportCells, editElementFlag, setLocalState]);

	let podWidthDisplayClass = usePodWidthDisplayClass(areaClass, podWidth);

	let narrowPodFlag = areaClass === "area1" || areaClass === "area2";

	return (
		<PodContext.Provider
			value={{
				areaClass,
				chartId,
				chartCustomFieldFlag,
				chartPodFlag,
				closeEditMode,
				customFieldFlag,
				editPodFlag,
				fieldId,
				gridReportFlag,
				hideBorderFlag: !editPodFlag && hideBorderFlag,
				narrowPodFlag,
				podWidth,
				podWidthDisplayClass,
				openEditMode,
				readOnlyFlag,
				updateModeFlag,
				...props,
			}}
		>
			<StyleWrapper opacity={lockedFlag ? 0.5 : 1} pointerEvents={lockedFlag ? "none" : "auto"} userSelect="none">
				{children}
			</StyleWrapper>
		</PodContext.Provider>
	);
};

let usePodContext = () => useContext(PodContext);

export { usePodContext };

PodProvider.propTypes = propTypes;
export default PodProvider;
