import PropTypes from "prop-types";

import FormField from "@clearpoint/old-theme/Form/Field/Field";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useFieldList, useFormFieldsLoading, useScorecardLockedFlag } from "@clearpoint/hooks";

import NoPeriodsWarning from "./NoPeriodsWarning";

let propTypes = {
	addFlag: PropTypes.bool,
};
let EditFieldsTab = ({ addFlag }) => {
	let objectId = useFormValue("objectId");
	let fieldList = useFieldList({ object: "scorecard", objectId, addFlag });
	let loadingFlag = useFormFieldsLoading({ fieldList, addFlag });
	let scorecardLockedFlag = useScorecardLockedFlag(objectId);

	return (
		<>
			<NoPeriodsWarning />
			{fieldList?.map((field) => (
				<FormField
					disabled={loadingFlag || scorecardLockedFlag}
					fieldId={field.value}
					key={field.value}
					label={field.label}
					object="scorecard"
				/>
			))}
		</>
	);
};
EditFieldsTab.propTypes = propTypes;
export default EditFieldsTab;
