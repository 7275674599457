import AlertUpsell from "@clearpoint/old-theme/Alert/Alert.Upsell";
import Block from "@clearpoint/old-theme/Block";
import Button from "@clearpoint/old-theme/Button/Button";
import ChartEmbedCodeDisplay from "./ChartEmbedCodeDisplay";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import FormSelectSort from "@clearpoint/old-theme/Form/Select/Select.Sort";
import Help from "@clearpoint/old-theme/Help";
import ListItemPlotBand from "@clearpoint/old-theme/ListItem/ListItem.PlotBand";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import { useCallback } from "react";
import { useFormContext } from "@clearpoint/old-theme/Form/Form";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import { useTranslate } from "@clearpoint/translate";
import { theme } from "@clearpoint/old-theme/Theme";
import { emptyArray } from "@clearpoint/utils";
import { useCheckFeature } from "@clearpoint/hooks";



let AdvancedOptionsTab = () => {
	let { enablePlotBands: enablePlotBandsFlag, published: publishedFlag } = useFormValue();
	let plotBandList = useFormValue("plotBands");
	let { setFormValue } = useFormContext();
	let translate = useTranslate();
	let checkFeature = useCheckFeature();

	let addPlotBand = useCallback(() => {
		setFormValue("plotBands", (plotBandList) => [
			...(plotBandList || emptyArray),
			{ color: null, to: null, from: null },
		]);
	}, [setFormValue]);

	return (
		<>
			<Help feature="chart-advanced" />
			<FormSelectSort name="sortSeries" label={translate("manage.chart.sortChartSeries")} />
			<FormCheck name="hiddenSummary">{translate("manage.chart.hiddenSummary")}</FormCheck>
			{checkFeature("embedCodes") ? (
				<FormCheck name="published">{translate("edit.chart.enableEmbedCode")}</FormCheck>
			) : (
				<AlertUpsell feature="embedCodes" />
			)}

			{publishedFlag && <ChartEmbedCodeDisplay translate={translate} />}
			{checkFeature("advancedCharting") && (
				<FormCheck name="enablePlotBands">{translate("edit.chart.addPlotBands")}</FormCheck>
			)}
			{enablePlotBandsFlag && (
				<FormGroup title={translate("edit.chart.plotBands")}>
					<StyleWrapper marginBottom={theme.space}>
						{plotBandList?.map((band, i) => (
							<ListItemPlotBand key={i} plotBandListNameInForm="plotBands" index={i} breakpoint="huge" />
						))}
						<Block textAlign="right">
							<Button onClick={addPlotBand} size="small" outlineFlag>
								{translate("edit.chart.addPlotBand")}
							</Button>
						</Block>
					</StyleWrapper>
				</FormGroup>
			)}
		</>
	);
};

export default AdvancedOptionsTab;
