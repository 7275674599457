import { useEffect, useMemo } from "react";
import { useFormContext } from "../Form";
import useFormValue from "./useFormValue";
import { useChartTypeList, usePrevious } from "@clearpoint/hooks";

let useWatchChartTypes = () => {
	let chartType = useFormValue("chartType");
	let { restoreFormValue, setFormValue, setSavedFormValue } = useFormContext();

	let { combinedChartTypeList } = useChartTypeList();
	let [pieFlag, gaugeFlag] = useMemo(() => {
		let currentSelectedChartTypeJson = combinedChartTypeList?.find((x) => x.id === chartType)?.chartJson;
		let pieRegex = /"type"\s*:\s*"pie"/;
		let gaugeRegex = /"type"\s*:\s*"(gauge|solidgauge)"/;
		return [pieRegex.test(currentSelectedChartTypeJson), gaugeRegex.test(currentSelectedChartTypeJson)];
	}, [chartType, combinedChartTypeList]);

	let previousPieFlag = usePrevious(pieFlag);
	let previousGaugeFlag = usePrevious(gaugeFlag);
	let { periodGroupId, periodDisplay, showFuture: showFutureFlag } = useFormValue();

	useEffect(() => {
		if (pieFlag || gaugeFlag) {
			let saveFormValueFlag = (gaugeFlag && !previousPieFlag) || (pieFlag && !previousGaugeFlag);
			setFormValue("periodGroupId", (periodGroupId) => {
				saveFormValueFlag && setSavedFormValue("periodGroupId", periodGroupId);
				return -1;
			});
			setFormValue("hiddenPeriods", (hiddenPeriods) => {
				saveFormValueFlag && setSavedFormValue("hiddenPeriods", hiddenPeriods);
				return [];
			});
			setFormValue("periodDisplay", (periodDisplay) => {
				saveFormValueFlag && setSavedFormValue("periodDisplay", periodDisplay);
				return "cp";
			});
			setFormValue("showFuture", (showFuture) => {
				saveFormValueFlag && setSavedFormValue("showFuture", showFuture);
				return true;
			});
			if (gaugeFlag) {
				setFormValue("chartSeries", (chartSeries) => {
					setSavedFormValue("chartSeries", chartSeries);
					return chartSeries?.slice(0, 1);
				});
			} else if (previousGaugeFlag) {
				restoreFormValue("chartSeries");
			}
		} else if (previousGaugeFlag || previousPieFlag) {
			if (periodDisplay === "cp") restoreFormValue("periodDisplay");
			if (periodGroupId === -1) restoreFormValue("periodGroupId");
			if (showFutureFlag) restoreFormValue("showFuture");
			restoreFormValue("chartSeries");
			restoreFormValue("hiddenPeriods");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gaugeFlag, pieFlag]);

	return { gaugeFlag };
};

export default useWatchChartTypes;
