let configurePane = (chartData) => {
	let {
		chartFormat: {
			chart: { type },
			pane,
		},
	} = chartData;

	if (type === "gauge") {
		return {
			...pane,
			center: ["50%", "55%"],
			size: "100%",
		};
	}

	if (type === "solidgauge") {
		return {
			...pane,
			size: "100%",
		};
	}

	return pane;
};

export default configurePane;
