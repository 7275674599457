import { useEffect, useMemo } from "react";
import { isArray } from "lodash";

import { useFormContext } from "@clearpoint/old-theme/Form/DefaultForm";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { usePrint } from "@clearpoint/hooks";

import { useOldSession } from "@clearpoint/old-session/index";

const ROW_COUNT_POD_OFFSET = 2;

export const ganttPrintFormKey = "print_ganttData";

export const useGanttPodPrint = (ganttValue) => {
	const isInPrint = usePrint();
	const { session } = useOldSession();
	const { rowCount } = session ?? {};
	const { setFormValue } = useFormContext();
	const printFormValue = useFormValue(ganttPrintFormKey);

	const podRowCountMax = rowCount > 5 ? rowCount - ROW_COUNT_POD_OFFSET : rowCount;

	const printData = useMemo(() => {
		if (!isInPrint || !isArray(ganttValue)) return [];
		return Array.from({ length: Math.ceil(ganttValue.length / podRowCountMax) }, (_v, i) =>
			ganttValue.slice(i * podRowCountMax, i * podRowCountMax + podRowCountMax)
		);
	}, [ganttValue, isInPrint, podRowCountMax]);

	useEffect(() => {
		if (!isInPrint) return;
		setFormValue(ganttPrintFormKey, printData);
	}, [isInPrint, printData, setFormValue]);

	return { isInPrint, printFormValue };
};
