let newUIHelpPathLookup = {
	"access-groups": "assigning+users+user+group",
	"access-scorecards": "managing+user+groups",
	"access-users": "assigning+users+user+group",
	"add-peer-measure": "adding+peer+data+measure+library",
	"add-user-security": "managing+users",
	"admin-direct-connections": "direct+connections+integration",
	"admin-menu": "renaming+elements+menu+items",
	"admin-options": "getting+started+administrator+options",
	"admin-recalculate": "recalculating+measure+scorecard",
	"admin-report-options": "understanding+report+options",
	"admin-schedules": "automating+your+reporting+process",
	"admin-webhooks": "webhooks+care+package",
	"advanced-series-options": "managing+summary+reports",
	"aggregate-series": "creating+aggregate+series",
	"aggregate-status": "creating+aggregate+status+calculation",
	ai: "ai+assistants",
	"alert-inbox": "navigating+alerts",
	"api-keys": "getting+started+clearpoint+api",
	archives: "snapshots",
	"attach-notification": "setting+new+notification",
	"audit-log": "undo+button+revision+history",
	"briefing-book-report-template": "managing+briefing+book+templates",
	"bulk-add-users": "managing+users",
	"bulk-change-action": "bulk+changes",
	"bulk-change-fields": "bulk+changes",
	"bulk-change-filter": "bulk+changes",
	"bulk-change-scripts": "bulk+change+scripts+care+package",
	"bulk-changes": "bulk+changes",
	"calculated-field": "creating+using+calculated+field",
	"calculated-fields": "creating+using+calculated+field",
	"calculated-series": "creating+series+calculations",
	"calculation-template": "default+series",
	categories: "adding+reordering+categories",
	"chart-advanced": "embedding+charts+from+clearpoint",
	"chart-axis": "managing+charts",
	"chart-custom-series": "creating+custom+charts",
	"chart-periods": "managing+charts",
	"chart-series": "managing+charts",
	"chart-transposed": "updating+measure+data",
	"completed-elements": "hiding+completed+elements",
	confidentiality: "adding+a+confidentiality+statement+to+a+briefing+book",
	"content-security": "understanding+login+user+options",
	"custom-charts": "creating+custom+charts",
	"custom-fields": "creating+and+using+custom+fields",
	"custom-menu": "managing+menu+and+element+names",
	"custom-script-definition": "template+scripting",
	"custom-scripts": "template+scripting",
	"data-loader": "data+loader+care+package",
	"data-loader-2": "data+loader+care+package",
	"default-charts": "setting+a+default+chart+type",
	"default-fields": "editing+default+field+labels",
	"default-layouts": "setting+default+page+layouts",
	"default-series": "default+series",
	"edit-chart": "managing+chart",
	"edit-detail-layout": "editing+objective+detail+pages",
	"edit-element": "getting+started+making+edits+updates",
	"edit-fields": "editing+vs+updating",
	"edit-integration": "direct+connections+integration",
	"edit-profile": "managing+users",
	"edit-report": "managing+summary+reports",
	"edit-series": "managing+series",
	"element-notifications": "setting+new+notification",
	"embedded-charts": "embedding+charts+from+clearpoint",
	"evaluation-criteria": "setting+objective+evaluations",
	"evaluation-template": "default+series",
	"field-labels": "editing+default+field+labels",
	"fiscal-year": "using+fiscal+years+clearpoint",
	"footer-styles": "branding+in+clearpoint",
	"gantt-chart-dates": "adding+a+gantt+chart+summary+report",
	goals: "okrs",
	"goals-individual-personal": "okrs",
	"goals-individual-list": "okrs+individuals",
	"goals-individual-tree": "okrs+individuals",
	"goals-manager-personal": "okrs+direct+reports",
	"goals-manager-list": "okrs+by+manager",
	"goals-manager-tree": "okrs+by+manager",
	"goals-department-personal": "okrs+departments",
	"goals-deparment-list": "okrs+by+departments",
	"goals-deparment-tree": "okrs+by+departments",
	"goals-scorecard-tree": "okrs+by+scorecard",
	"header-styles": "creating+custom+report+styles",
	"html-export-report-template": "managing+html+templates",
	"ip-whitelist": "ip+restrictions",
	"integrations-data-loader": "data+loader",
	"integrations-clearpoint-api": "clearpoint+api",
	"integrations-zapier": "zapier",
	"integrations-bulk-changes": "bulk+changes",
	"integrations-scripting": "scripting",
	"license-details": "license+details",
	linkages: "linking+elements",
	links: "linking+elements",
	"lock-account": "understanding+login+user+options",
	"manage-action-item": "managing+action+items",
	"manage-fiscal-years": "using+fiscal+years+clearpoint",
	"manage-category": "managing-categories",
	"manage-elements": "managing+elements",
	"manage-initiative": "managing+initiatives",
	"manage-layouts": "managing+summary+reports",
	"manage-map": "adding+and+editing+strategy+maps",
	"manage-master-periods": "accessing+measure+library",
	"manage-measure": "managing+measures",
	"manage-notification-template": "notifications+for+your+reporting+process",
	"manage-objective": "managing+objectives",
	"manage-period-groups": "adding+editing+reporting+frequencies",
	"manage-periods": "managing+reporting+periods",
	"manage-risk": "managing+risks",
	"manage-schedules": "automating+your+reporting+process",
	"manage-scorecards": "managing+scorecards",
	"manage-user-groups": "managing+user+groups",
	"manage-users": "managing+users",
	"manage-workflows": "reporting+workflows",
	"measure-charts": "managing+charts",
	"measure-library": "peer+to+peer+sharing",
	"measure-series": "adding+a+series",
	milestones: "managing+elements",
	"my-favorites": "adding+a+page+in+clearpoint+to+my+favorites",
	"my-notifications": "using+my+favorites+my+scorecard+and+my+notifications",
	"my-profile": "managing+my+profile",
	"my-profile-security": "enabling+two+factor+authentication",
	"my-scorecard": "using+my+favorites+my+scorecard+and+my+notifications",
	"my-updates": "my+updates",
	"my-mentions": "comments+and+mentions",
	"notification-elements": "setting+new+notification",
	"notification-name": "notifications+for+your+reporting+process",
	"notification-overview": "notification+options",
	"notification-recipients": "notifications+for+your+reporting+process",
	"notification-save-template": "notifications+for+your+reporting+process",
	"notification-template": "notifications+for+your+reporting+process",
	notifications: "setting+new+notification",
	"number-formats": "creating+custom+number+formats",
	"objective-evaluations": "setting+objective+evaluations",
	"organization-details": "understanding+login+user+options",
	package: "mapping+data+loader+packages+series+ids",
	"parameter-preview": "template+scripting",
	"password-complexity": "understanding+login+user+options",
	"peer-measure": "measure+library+care+package",
	"peer-measure-preview": "measure+library+care+package",
	"peer-sharing": "peer+to+peer+sharing",
	"period-auto-locking": "locking+periods",
	"period-by-scorecard": "managing+reporting+periods",
	"period-group-scorecards": "managing+reporting+periods",
	"period-locking": "locking+periods",
	"pod-styles": "creating+custom+pod+styles",
	"points-of-contact": "understanding+login+user+options",
	"project-evaluation": "project+evaluations",
	"recycle-bin": "recycle+bin",
	"reference-series": "creating+reference+series",
	"reminder-dates": "scheduling+sending+email+reminders",
	"reminder-elements": "scheduling+sending+email+reminders",
	"reminder-owners": "scheduling+sending+email+reminders",
	"reminder-preview": "scheduling+sending+email+reminders",
	"reminder-recipients": "scheduling+sending+email+reminders",
	"reminder-scorecards": "scheduling+sending+email+reminders",
	"reminder-status": "scheduling+sending+email+reminders",
	reminders: "scheduling+sending+email+reminders",
	"report-access": "editing+a+briefing+book+template",
	"report-access-type": "setting+access+rules+or+visibility+of+a+summary+report",
	"report-access-type-admin": "setting+access+rules+or+visibility+of+a+summary+report",
	"report-columns": "creating+a+summary+report",
	"report-filter": "end+user+summary+reports",
	"report-footer": "branding+in+clearpoint",
	"report-grid-order": "managing+summary+reports",
	"report-header": "branding+in+clearpoint",
	"report-page-breaks": "managing+summary+reports",
	"report-preview": "editing+a+briefing+book+template",
	"report-recipients": "adding+recipients+to+a+report",
	"report-scorecards": "editing+a+briefing+book+template",
	"report-select-elements": "managing+summary+reports",
	"report-styles": "creating+custom+report+styles",
	"report-template-elements": "managing+html+templates",
	"report-template-library": "creating+summary+report+templates",
	"report-toc": "editing+a+briefing+book+template",
	"reporting-frequency": "adding+editing+reporting+frequencies",
	"reporting-period": "managing+reporting+periods",
	"restricted-reports": "setting+access+rules+or+visibility+of+a+summary+report",
	"restricted-templates": "setting+access+rules+for+a+briefing+book",
	"schedule-package": "creating+data+loader+package+schedules",
	"schedule-reminder": "automating+your+reporting+process",
	"schedule-report": "managing+schedules",
	scheduler: "automating+your+reporting+process",
	schedules: "managing+schedules",
	"scorecard-user-access": "setting+user+permissions+scorecard",
	scripts: "template+scripting",
	"script-parameters": "template+scripting",
	"script-preview": "template+scripting",
	search: "searching+in+clearpoint",
	"send-reminders": "scheduling+sending+email+reminders",
	"series-evaluations": "setting+measure+evaluations",
	snapshots: "snapshots",
	"status-by-custom-field": "adding+editing+and+re+ordering+status+indicators",
	"status-by-element": "adding+editing+and+re+ordering+status+indicators",
	"status-icons": "adding+editing+and+re+ordering+status+indicators",
	"strategy-map": "adding+and+editing+strategy+maps",
	styles: "branding+in+clearpoint",
	"subtable-options": "understanding+report+options",
	"table-styles": "creating+custom+table+css+styles",
	"tag-elements": "tags",
	tags: "tags",
	"templates-attachments": "adding+attachments+uploading+files+clearpoint",
	"templates-exports": "exporting+single+pages",
	"templates-html": "managing+html+templates",
	"templates-dash": "managing+dashboard+templates",
	"templates-images": "image+library",
	"templates-reports": "managing+briefing+book+templates",
	"update-fields": "editing+vs+updating",
	"user-groups": "managing+user+groups",
	"user-scorecards": "setting+user+permissions+scorecard",
	"user-security": "understanding+login+user+options",
	webhooks: "webhooks+care+package",
	workflows: "reporting+workflows",
	"workflow-email-overview": "reporting+workflow",
	"workflow-email-tokens": "reporting+workflow",
	"workflow-emails": "reporting+workflows",
	"workflow-overview": "reporting+workflows",
	"workflow-schedules": "scheduling+workflow",
	"workflow-scorecards": "getting+started+scorecards",
};

let helpPathLookup = {
	"access-groups": "assigning-users-user-group",
	"access-scorecards": "managing-user-groups",
	"access-users": "assigning-users-user-group",
	"add-peer-measure": "adding-peer-data-measure-library",
	"add-user-security": "managing-users",
	"admin-direct-connections": "direct-connections-integration",
	"admin-menu": "renaming-elements-menu-items",
	"admin-options": "getting-started-administrator-options",
	"admin-recalculate": "recalculating-measure-scorecard",
	"admin-report-options": "understanding-report-options",
	"admin-schedules": "automating-your-reporting-process",
	"admin-webhooks": "webhooks-care-package",
	"advanced-series-options": "managing-summary-reports/#sect4",
	"aggregate-series": "creating-aggregate-series",
	"aggregate-status": "creating-aggregate-status-calculation",
	"alert-inbox": "navigating-alerts",
	"api-keys": "getting-started-clearpoint-api",
	archives: "snapshots",
	"attach-notification": "setting-new-notification",
	"audit-log": "undo-button-revision-history",
	"briefing-book-report-template": "managing-briefing-book-templates",
	"bulk-add-users": "managing-users",
	"bulk-change-action": "bulk-changes",
	"bulk-change-fields": "bulk-changes",
	"bulk-change-filter": "bulk-changes",
	"bulk-change-scripts": "bulk-change-scripts-care-package",
	"bulk-changes": "bulk-changes",
	"calculated-field": "creating-using-calculated-field",
	"calculated-fields": "creating-using-calculated-field",
	"calculated-series": "creating-series-calculations",
	"calculation-template": "default-series",
	categories: "adding-reordering-categories",
	"chart-advanced": "embedding-charts-from-clearpoint",
	"chart-axis": "managing-charts",
	"chart-custom-series": "creating-custom-charts",
	"chart-periods": "managing-charts",
	"chart-series": "managing-charts",
	"chart-transposed": "updating-measure-data",
	"completed-elements": "hiding-completed-elements",
	confidentiality: "adding-a-confidentiality-statement-to-a-briefing-book",
	"content-security": "understanding-login-user-options",
	"custom-charts": "creating-custom-charts",
	"custom-fields": "creating-and-using-custom-fields",
	"custom-menu": "managing-menu-and-element-names",
	"custom-script-definition": "template-scripting",
	"custom-scripts": "template-scripting",
	"data-loader": "data-loader-care-package",
	"data-loader-2": "data-loader-care-package",
	"default-charts": "setting-a-default-chart-type",
	"default-fields": "editing-default-field-labels",
	"default-layouts": "setting-default-page-layouts",
	"default-series": "default-series",
	"edit-chart": "managing-charts",
	"edit-detail-layout": "editing-objective-detail-pages",
	"edit-element": "getting-started-making-edits-updates",
	"edit-fields": "editing-vs-updating",
	"edit-integration": "direct-connections-integration",
	"edit-profile": "managing-users",
	"edit-report": "managing-summary-reports/#sect1",
	"edit-series": "managing-series",
	"element-notifications": "setting-new-notification",
	"embedded-charts": "embedding-charts-from-clearpoint",
	"evaluation-criteria": "setting-objective-evaluations",
	"evaluation-template": "default-series",
	"field-labels": "editing-default-field-labels",
	"fiscal-year": "using-fiscal-years-clearpoint",
	"footer-styles": "branding-in-clearpoint",
	"gantt-chart-dates": "adding-a-gantt-chart-summary-report",
	"header-styles": "creating-custom-report-styles",
	"html-export-report-template": "managing-html-templates",
	"ip-whitelist": "ip-restrictions",
	"license-details": "license-details",
	linkages: "linking-elements",
	links: "linking-elements",
	"lock-account": "understanding-login-user-options",
	"manage-action-item": "adding-action-items",
	"manage-fiscal-years": "using-fiscal-years-clearpoint",
	"manage-initiative": "managing-initiatives",
	"manage-layouts": "managing-summary-reports",
	"manage-map": "adding-and-editing-strategy-maps",
	"manage-master-periods": "accessing-measure-library/#sect2",
	"manage-measure": "managing-measures",
	"manage-notification-template": "notifications-for-your-reporting-process",
	"manage-objective": "managing-objectives",
	"manage-period-groups": "adding-editing-reporting-frequencies",
	"manage-periods": "managing-reporting-periods",
	"manage-risk": "adding-an-element",
	"manage-schedules": "automating-your-reporting-process",
	"manage-scorecard": "managing-scorecards",
	"manage-user-groups": "managing-user-groups",
	"manage-users": "managing-users",
	"manage-workflows": "reporting-workflows",
	"measure-charts": "managing-charts",
	"measure-library": "peer-to-peer-sharing",
	"measure-series": "adding-a-series",
	milestones: "managing-initiatives",
	"my-favorites": "adding-a-page-in-clearpoint-to-my-favorites",
	"my-notifications": "using-my-favorites-my-scorecard-and-my-notifications",
	"my-profile": "managing-my-profile",
	"my-profile-security": "enabling-two-factor-authentication",
	"my-scorecard": "using-my-favorites-my-scorecard-and-my-notifications",
	"my-updates": "my-updates",
	"notification-elements": "setting-new-notification",
	"notification-name": "notifications-for-your-reporting-process",
	"notification-overview": "notification-options",
	"notification-recipients": "notifications-for-your-reporting-process",
	"notification-save-template": "notifications-for-your-reporting-process",
	"notification-template": "notifications-for-your-reporting-process",
	notifications: "setting-new-notification",
	"number-formats": "creating-custom-number-formats",
	"objective-evaluations": "setting-objective-evaluations",
	"organization-details": "understanding-login-user-options",
	package: "mapping-data-loader-packages-series-ids",
	"parameter-preview": "template-scripting",
	"password-complexity": "understanding-login-user-options",
	"peer-measure": "measure-library-care-package",
	"peer-measure-preview": "measure-library-care-package",
	"peer-sharing": "peer-to-peer-sharing",
	"period-auto-locking": "locking-periods",
	"period-by-scorecard": "managing-reporting-periods",
	"period-group-scorecards": "managing-reporting-periods",
	"period-locking": "locking-periods",
	"pod-styles": "creating-custom-pod-styles",
	"points-of-contact": "understanding-login-user-options",
	"project-evaluation": "project-evaluations",
	"recycle-bin": "recycle-bin",
	"reference-series": "creating-reference-series",
	"reminder-dates": "scheduling-sending-email-reminders",
	"reminder-elements": "scheduling-sending-email-reminders",
	"reminder-owners": "scheduling-sending-email-reminders",
	"reminder-preview": "scheduling-sending-email-reminders",
	"reminder-recipients": "scheduling-sending-email-reminders",
	"reminder-scorecards": "scheduling-sending-email-reminders",
	"reminder-status": "scheduling-sending-email-reminders",
	reminders: "scheduling-sending-email-reminders",
	"report-access": "editing-a-briefing-book-template",
	"report-access-type": "setting-access-rules-or-visibility-of-a-summary-report",
	"report-access-type-admin": "setting-access-rules-or-visibility-of-a-summary-report",
	"report-columns": "creating-a-summary-report",
	"report-filter": "end-user-summary-reports",
	"report-footer": "branding-in-clearpoint/#sect8",
	"report-grid-order": "managing-summary-reports",
	"report-header": "branding-in-clearpoint",
	"report-page-breaks": "managing-summary-reports",
	"report-preview": "editing-a-briefing-book-template",
	"report-recipients": "adding-recipients-to-a-report",
	"report-scorecards": "editing-a-briefing-book-template",
	"report-select-elements": "managing-summary-reports",
	"report-styles": "creating-custom-report-styles",
	"report-template-elements": "managing-html-templates",
	"report-template-library": "creating-summary-report-templates",
	"report-toc": "editing-a-briefing-book-template",
	"reporting-frequency": "adding-editing-reporting-frequencies",
	"reporting-period": "managing-reporting-periods",
	"restricted-reports": "setting-access-rules-or-visibility-of-a-summary-report",
	"restricted-templates": "setting-access-rules-for-a-briefing-book",
	"schedule-package": "creating-data-loader-package-schedules",
	"schedule-reminder": "automating-your-reporting-process",
	"schedule-report": "managing-schedules",
	scheduler: "automating-your-reporting-process",
	schedules: "managing-schedules",
	"scorecard-user-access": "setting-user-permissions-scorecard",
	scripts: "template-scripting",
	"script-parameters": "template-scripting",
	"script-preview": "template-scripting",
	search: "searching-in-clearpoint",
	"send-reminders": "scheduling-sending-email-reminders",
	"series-evaluations": "setting-measure-evaluations",
	snapshots: "snapshots",
	"status-by-custom-field": "adding-editing-and-re-ordering-status-indicators",
	"status-by-element": "adding-editing-and-re-ordering-status-indicators",
	"status-icons": "adding-editing-and-re-ordering-status-indicators",
	"strategy-map": "adding-and-editing-strategy-maps",
	styles: "branding-in-clearpoint",
	"subtable-options": "understanding-report-options",
	"table-styles": "creating-custom-table-css-styles",
	"tag-elements": "tags",
	tags: "tags",
	"templates-attachments": "adding-attachments-uploading-files-clearpoint",
	"templates-exports": "exporting-single-pages",
	"templates-html": "managing-html-templates",
	"templates-images": "image-library",
	"templates-reports": "managing-briefing-book-templates",
	"update-fields": "editing-vs-updating",
	"user-groups": "managing-user-groups",
	"user-scorecards": "setting-user-permissions-scorecard",
	"user-security": "understanding-login-user-options",
	webhooks: "webhooks-care-package",
	workflows: "reporting-workflows",
	"workflow-schedules": "reporting-workflows",
	"workflow-email-overview": "reporting-workflows",
	"workflow-email-tokens": "reporting-workflows",
	"workflow-emails": "reporting-workflows",
	"workflow-overview": "reporting-workflows",
	"workflow-scorecards": "getting-started-scorecards",
};

export { helpPathLookup, newUIHelpPathLookup };
